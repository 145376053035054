import auth from "@/store/modules/auth";
import Vuex from 'vuex'
import Vue from 'vue'
import lanes from "@/store/modules/lanes";
import metrics from "@/store/modules/metrics";
import createPersistedState from "vuex-persistedstate";
import hour from "@/store/modules/hour";

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        // módulo para login & logout
        auth: auth,
        lanes: lanes,
        metrics: metrics,
        hour: hour,
    },
    plugins: [createPersistedState()],
});
