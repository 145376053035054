<template>
  <v-container fill-height>
    <v-container v-if="editSelection === false">
      <h2 class="my-5">Turnos</h2>
      <DeletableTable
          :editable="true"
          :headers="headers"
          :items="turns"
          btnText="Crear Turno"
          @create="onCreateTurn"
          @edit="onEditTurn"
      >
      </DeletableTable>
    </v-container>

    <v-container v-else fill-height :key="shiftsKey">
      <v-row justify="center" align="center" v-if="isLoading">
        <v-col cols="12" sm="4">
          <v-progress-circular
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-container v-else>
        <v-btn color="primary" depressed @click="goBack">
          <v-icon dark left> mdi-arrow-left</v-icon>
          Regresar
        </v-btn>
        <h2 class="my-5">Turno {{ turnName }}</h2>

        <v-expansion-panels v-model="expandedItem" accordion @change="onExpandedLane">
          <v-expansion-panel
              v-for="tempLane in lanes"
              :key="tempLane.id"
          >
            <v-expansion-panel-header>{{ tempLane.name }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <DeletableTable
                  :deletable="true"
                  :headers="headersTurn"
                  :items="schedule"
                  btnText="Agregar horario"
                  @create="onCreateSchedule"
                  @delete="onDeleteSchedule"
              >
              </DeletableTable>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-container>
    </v-container>

    <DialogForm
        ref="createTurnDialog"
        need-close
        title="Nuevo Turno"
        @accept="createTurn"
    >
      <template #form>
        <v-text-field
            v-model="nameTurnForm"
            :autofocus="true"
            class="mx-5"
            label="Nombre del turno"
        >
        </v-text-field>
      </template>
    </DialogForm>

    <DialogForm
        ref="createScheduleDialog"
        need-close
        title="Nuevo Horario"
        @accept="createSchedule"
        
    >
      <template #form >
        <v-container>
          <v-row>
            <v-col>
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  max-width="290px"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="time"
                      label="Hora de entrada"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu"
                    v-model="time"
                    :use-seconds="true"
                    format="24hr"
                    full-width
                    @click:second="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
              <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time2"
                  max-width="290px"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="time2"
                      label="Hora de salida"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu2"
                    v-model="time2"
                    :use-seconds="true"
                    format="24hr"
                    full-width
                    @click:second="$refs.menu2.save(time2)"
                ></v-time-picker>
              </v-menu>
              <v-text-field
                  v-model="timeAvailable"
                  label="Tiempo disponible"
                  name="timeAvailable"
                  @keyup.native.enter="$refs.createScheduleDialog.acceptDialog()"
              ></v-text-field>
            </v-col>
            <v-col>
              <h3>Dias de la semana</h3>
              <v-checkbox
                  v-model="selected"
                  label="Lunes"
                  value="Lunes"
              ></v-checkbox>
              <v-checkbox
                  v-model="selected"
                  label="Martes"
                  value="Martes"
              ></v-checkbox>
              <v-checkbox
                  v-model="selected"
                  label="Miercoles"
                  value="Miercoles"
              ></v-checkbox>
              <v-checkbox
                  v-model="selected"
                  label="Jueves"
                  value="Jueves"
              ></v-checkbox>
              <v-checkbox
                  v-model="selected"
                  label="Viernes"
                  value="Viernes"
              ></v-checkbox>
              <v-checkbox
                  v-model="selected"
                  label="Sabado"
                  value="Sabado"
              ></v-checkbox>
              <v-checkbox
                  v-model="selected"
                  label="Domingo"
                  value="Domingo"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </DialogForm>

    <DialogForm
        ref="deleteScheduleDialog"
        need-close
        title="Borrar Horario"
        @accept="deleteSchedule"
        
    >
      <template #form>
        <v-container class="pa-2">
          <h3>¿Estas seguro de borrar el horario?</h3>
        </v-container>
      </template>
    </DialogForm>
  </v-container>
</template>

<script>
import DeletableTable from "@/components/DeletableTable.vue";
import DialogForm from "@/components/DialogForm.vue";
import axios from "axios";

export default {
  inject: ["showSnackbar"],
  data() {
    return {
      turns: [],
      headers: [
        {
          text: "Turno",
          align: "start",
          sortable: false,
          value: "name",
        },
      ],
      nameTurnForm: "",
      turnName: "",
      turnId: "",
      shiftId: "",
      headersTurn: [
        {
          text: "Dia",
          align: "start",
          sortable: false,
          value: "day",
        },
        {
          text: "Hora Entrada",
          align: "start",
          sortable: false,
          value: "start_hour",
        },
        {
          text: "Hora Salida",
          align: "start",
          sortable: false,
          value: "end_hour",
        },
        {
          text: "Minutos Disponibles",
          align: "start",
          sortable: false,
          value: "available_minutes",
        },
      ],
      schedule: [],
      editSelection: false,
      isLoading: false,
      selected: [],
      time: null,
      menu: false,
      time2: null,
      menu2: false,
      timeAvailable: 0,
      shiftsKey: 100,
      expandedItem: null,
      lanes: this.$store.getters["lanes/lanes"]
    };
  },
  methods: {
    getDataTurns() {
      try {
        axios
            .get(process.env.VUE_APP_APIURL + "shifts/", {
              headers: {
                Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
              },
            })
            .then((res) => {
              this.turns = [...res.data];
            });
      } catch (error) {
        this.showSnackbar("error", error);
      }
    },
    getTurnData() {
      this.schedule = [];
      if (this.expandedItem === null || this.expandedItem === undefined) {
        return;
      }

      try {
        axios
            .get(
                process.env.VUE_APP_APIURL + `schedulesForShifts/${this.turnId}/${this.lanes[this.expandedItem].id}`,
                {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
                  },
                }
            )
            .then((res) => {
              if (res.data.length !== 0) {
                this.schedule = [...res.data];
              }
              this.isLoading = false;
            });
      } catch (error) {
        this.showSnackbar("error", error);
      }
    },
    postScheldule() {
      for (const day of this.selected) {
        let data = {
          shift_id: this.turnId,
          start_hour: this.time,
          end_hour: this.time2,
          day: day,
          available_minutes: this.timeAvailable,
          lane_id: this.lanes[this.expandedItem].id
        };
        try {
          axios
              .post(process.env.VUE_APP_APIURL + "schedule/", data, {
                headers: {
                  Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
                },
              })
              .then((res) => {
                this.showSnackbar("success", "Se registro correctamente");
                this.getTurnData();
              });
        } catch (error) {
          this.showSnackbar("error", "Error al registrar horario");
        }
      }
    },
    onCreateTurn() {
      this.$refs.createTurnDialog.openDialog();
    },
    onEditTurn(turn) {
      this.editSelection = true;
      this.turnName = turn.name;
      this.turnId = turn.id;
      this.getTurnData();
    },
    createTurn() {
      let data = {name: this.nameTurnForm};
      try {
        axios
            .post(process.env.VUE_APP_APIURL + "shift/", data, {
              headers: {
                Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
              },
            })
            .then((response) => {
              this.showSnackbar("success", "Se creo el registro");
              this.getDataTurns();
            });
      } catch (error) {
        this.showSnackbar("error", error);
      }
    },
    onDeleteSchedule(item) {
      this.shiftId = item.id;
      this.$refs.deleteScheduleDialog.openDialog();
    },
    onCreateSchedule() {
      this.$refs.createScheduleDialog.openDialog();
    },
    createSchedule() {
      if (
          this.selected.length !== 0 &&
          this.time.length !== 0 &&
          this.time2.length !== 0 &&
          this.timeAvailable.length !== 0 &&
          parseInt(this.timeAvailable) <= 60
      ) {
        this.postScheldule();
      } else {
        if (parseInt(this.timeAvailable) > 60) {
          this.showSnackbar(
              "error",
              "El tiempo disponible no puede ser mayor a 60"
          );
        } else {
          this.showSnackbar("error", "Faltan campos en el formulario");
        }
      }
    },
    deleteSchedule() {
      try {
        axios
            .delete(process.env.VUE_APP_APIURL + "schedule/" + this.shiftId, {
              headers: {
                Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
              },
            })
            .then((response) => {
              this.getTurnData();
              this.showSnackbar("success", "Se elimino correctamente");
            })
            .catch((e) => {
              this.showSnackbar("error", e);
            });
      } catch (error) {
        this.showSnackbar("error", error);
      }
    },
    goBack() {
      this.editSelection = false;
      this.schedule = [];
      this.expandedItem = null;
    },
    onExpandedLane() {
      this.getTurnData();
    },
  },
  created() {
    this.getDataTurns();
  },
  components: {DeletableTable, DialogForm},
};
</script>

<style></style>
