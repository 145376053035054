<template>

  <div class="d-flex justify-end flex-column">
    <v-data-table :headers="headers" :items="actionsData" class="elevation-1" hide-default-footer disable-sort>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ACCIONES A MÉTRICOS</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>


      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)" dark>
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="" small @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <v-row class="ml-auto mt-4">
      <v-btn color="primary" depressed @click="createAction">Agregar accion</v-btn>
    </v-row>


    <dialog-form
        ref="editAction"
        is-form
        needClose
        title="Editar acción"
        @accept="onAcceptEdit"
    >
      <template v-slot:form>
        <v-text-field
            v-model="editedItem.causes"
            label="Causa"
            required
        ></v-text-field>
        <v-text-field
            v-model="editedItem.action"
            label="Acción"
            required
        ></v-text-field>
        <v-text-field
            v-model="editedItem.responsible"
            label="Responsable"
            required
        ></v-text-field>
        <v-select
            v-model="editedItem.status"
            :items="actionStatus"
            label="Estatus"
            outlined
        ></v-select>
      </template>
    </dialog-form>

    <dialog-form
        ref="createAction"
        is-form
        needClose
        title="Crear acción"
        @accept="onAcceptCreate"
    >
      <template v-slot:form>
        <v-text-field
            v-model="editedItem.causes"
            label="Causa"
            required
            class="ma-2"
        ></v-text-field>
        <v-text-field
            v-model="editedItem.action"
            label="Acción"
            required
            class="ma-2"
        ></v-text-field>
        <v-text-field
            v-model="editedItem.responsible"
            label="Responsable"
            required
            class="ma-2"
        ></v-text-field>
        <v-select
            v-model="editedItem.status"
            :items="actionStatus"
            label="Estatus"
            outlined
            required
            class="ma-2"
        ></v-select>
      </template>
    </dialog-form>
  </div>

</template>

<script>
import DialogForm from "@/components/DialogForm";
import {StatusEnum} from "@/enums";

export default {
  components: {
    DialogForm,
  },
  data() {
    return {
      headers: [
        {
          text: "Turno",
          align: "start",
          sortable: false,
          value: "shift__name",
        },
        {text: "Causa del problema", value: "causes"},
        {text: "Acción", value: "action"},
        {text: "Resp.", value: "responsible"},
        {text: "Fecha", value: "created"},
        {text: "Estatus", value: "status"},
        {text: "Editar", value: "actions", sortable: false}
      ],
      editedIndex: -1,
      editedItem: {
        causes: "",
        action: "",
        responsible: "",
        status: "Pendiente",
      },
      dialog: false,
      dialogDelete: false,
      actionStatus: Object.values(StatusEnum),
    };
  },
  methods: {
    getColor(status) {
      if (status === "Completado") return 'green'
      else if (status === "En proceso") return 'orange'
      else return 'red'
    },
    editItem(item) {
      this.editedItem = {...item}
      this.$refs.editAction.openDialog();
    },
    onAcceptEdit() {
      this.$emit('editItem', {...this.editedItem});
      this.editedItem = {
        causes: "",
        action: "",
        responsible: "",
        status: "Pendiente",
      }
    },
    createAction() {
      this.editedItem = {
        causes: "",
        action: "",
        responsible: "",
        status: "Pendiente",
      }
      this.$refs.createAction.openDialog();
    },
    onAcceptCreate() {
      this.$emit('createAction', {...this.editedItem});
      this.editedItem = {
        causes: "",
        action: "",
        responsible: "",
        status: "Pendiente",
      }
    }
  },
  props: {
    actionsData: {type: Array}
  },


};
</script>

<style>
</style>
