<template>
  <v-container>

    <!-- Speedometer -->
    <v-container @click="hiddenSpeedometer = true" v-if="hiddenSpeedometer === false">
      <v-row>
        <v-col cols="12">
          <h4 class="text-center">Porcentaje de Efectividad</h4>
          <v-container class="d-flex justify-center" width="100%" @click="hiddenSpeedometer = true">
            <vue-speedometer
                :minValue="0"
                :maxValue="100"
                :forceRender="true"
                :maxSegmentLabels="1"
                :customSegmentStops="[0, 15, 20, 100]"
                :segmentColors="['#66BB6A', '#FF9800', '#F44336']"
                needleColor="#000"
                :currentValueText="'Porcentaje Actual: \${value}'"
                :value="speedDometer"
                textColor="${textColor}"
                :width="getSpeedometerWidth"
            />
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <!-- Charts -->
    <v-container v-else>
      <v-row>
        <v-col cols="12">
          <h4 class="text-center">Anual</h4>
          <v-container>
            <BarChartMetric
                :chartData="yearData"
                :tooltipText="tooltipText"
                @giveIndexColumn="showYearTable = !showYearTable"
            ></BarChartMetric>
            <chart-table-year v-if="showYearTable" :data="expandedYearData" :headers="headers"
                              @expandedDay="atExpandedYearDate"/>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-container>
            <h4 class="text-center">Semanal</h4>
            <BarChartMetric
                :chartData="monthData"
                :tooltipText="tooltipText"
                @giveIndexColumn="showMonthTable = !showMonthTable"
            ></BarChartMetric>
            <chart-table-week v-if="showMonthTable" :data="expandedMonthData" :headers="headers"
                              @expandedDay="atExpandedMonthDate"/>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
  </v-container>
</template>

<script>
import BarChartMetric from "./BarChartMetric.vue";
import ActionTable from "./ActionTable.vue";
import VueSpeedometer from "vue-speedometer";
import DataBackgroundEnum from "@/enums/modules/DataBackgroundEnum";
import ChartTableYear from "@/components/ChartTableYear";
import ChartTableWeek from "@/components/ChartTableWeek";
import ChartTable from "@/components/ChartTable";

export default {
  props: {
    speedDometer: {
      type: Number,
      default: 10,
    },
    chartsData: {
      type: Array,
    },
    actionsData: {
      type: Array
    },
    tooltipText: {
      type: String,
    },
    expandedYearData: {
      type: Array,
      required: true
    },
    expandedMonthData: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      hiddenSpeedometer: false,
      headers: [
        {
          text: this.tooltipText,
          align: "start",
          sortable: false,
          value: "percentage",
        },
      ],
      showYearTable: false,
      showMonthTable: false,
      window: {
        width: 0,
        height: 0
      },
    };
  },
  components: {BarChartMetric, ActionTable, VueSpeedometer, ChartTableYear, ChartTableWeek, ChartTable},
  methods: {
    atExpandedYearDate(item) {
      this.$emit("yearData", item);
    },
    atExpandedMonthDate(item) {
      this.$emit("monthData", item);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  computed: {
    yearData() {
      const dataObj = this.chartsData[0]
      dataObj.datasets[0].backgroundColor =
          dataObj.datasets[0].data.map((value) => {
            if (value >= 85) {
              return DataBackgroundEnum.GREEN;
            }
            return DataBackgroundEnum.RED;
          });
      return dataObj
    },
    monthData() {
      const dataObj = this.chartsData[1]
      dataObj.datasets[0].backgroundColor =
          dataObj.datasets[0].data.map((value) => {
            if (value >= 85) {
              return DataBackgroundEnum.GREEN;
            }
            return DataBackgroundEnum.RED;
          });
      return dataObj
    },
    getSpeedometerWidth() {
      if (this.window.width >= 768)
        return 500;
      else if (this.window.width >= 450)
        return 320;
      else
        return 200;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style>
</style>