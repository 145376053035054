import Vue from 'vue'
import VueRouter from 'vue-router'
import Notification from "@/views/Notification";
import Problems from "@/views/Problems";
import AcceptanceCriteria from "@/views/AcceptanceCriteria";
import Users from "@/views/Users";
import HourByHour from "@/views/HourByHour";
import Charts from "@/views/Charts";
import Metrics from "@/views/MetricsView.vue";
import Gemba from "@/views/Gemba";
import Security from "@/views/Security";
import BusinessUnits from "@/views/BusinessUnits";
import Model from "@/views/Model";
import FiveS from "@/views/FiveS.vue";
import FiveWhy from "@/views/FiveWhy";
import LoginView from "@/views/LoginView";
import TurnsView from "@/views/TurnsView";
import Lane from "@/views/Lane";
import axios from "axios";
import {UserTypeEnum} from "@/enums";

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        name: 'Login',
        component: LoginView
    },
    {
        path: '/notifications',
        name: 'Notification',
        component: Notification,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: '/problems',
        name: 'Problems',
        component: Problems,
        meta: {
            requiresSupervisor: true
        }
    },
    {
        path: '/acceptance_criteria',
        name: 'AcceptanceCriteria',
        component: AcceptanceCriteria,
        meta: {
            requiresSupervisor: true
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: '/hour',
        name: 'Hour',
        component: HourByHour,
        meta: {}
    },
    {
        path: '/charts',
        name: 'Charts',
        component: Charts
    },
    {
        path: '/gemba',
        name: 'Gemba',
        component: Gemba
    },
    {
        path: '/metrics',
        name: 'Metrics',
        component: Metrics
    },
    {
        path: '/security',
        name: 'Security',
        component: Security,
    },
    {
        path: '/businessUnits',
        name: 'BusinessUnits',
        component: BusinessUnits,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: '/model',
        name: 'Model',
        component: Model,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: '/fiveS',
        name: 'FiveS',
        component: FiveS,
        meta: {
            requiresSupervisor: true
        }
    },
    {
        path: '/fiveW',
        name: 'FiveW',
        component: FiveWhy,
        meta: {
            requiresSupervisor: true
        }
    },
    {
        path: '/shifts',
        name: 'TurnsView',
        component: TurnsView,
        meta: {
            requiresAdmin: true
        }
    },
    {
        path: '/lanes',
        name: 'Lane',
        component: Lane,
        meta: {
            requiresAdmin: true
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (checkIfIsLogin(to, next) === false) {
        if (router.app.$store.getters["auth/token"] === null || router.app.$store.getters["auth/token"] === undefined) {
            next({name: "Login"});
        } else {
            if (to.matched.some(record => record.meta.requiresSupervisor)) {
                supervisorProtection(to, from, next);
            } else if (to.matched.some(record => record.meta.requiresAdmin)) {
                adminProtection(to, from, next);
            } else {
                validateToken(next);
            }
        }
    }
})

function supervisorProtection(to, from, next) {
    if (router.app.$store.getters["auth/userType"] === UserTypeEnum.SUPERVISOR || router.app.$store.getters["auth/userType"] === UserTypeEnum.ADMIN) {
        validateToken(next);
    } else {
        next({name: "Hour"})
    }
}

function adminProtection(to, from, next) {
    if (router.app.$store.getters["auth/userType"] === UserTypeEnum.ADMIN) {
        validateToken(next);
    } else {
        next({name: "Hour"})
    }
}

const checkIfIsLogin = (to, next) => {
    if (to.name === "Login") {
        router.app.$store.dispatch("auth/logout");
        next();
        return true;
    }
    return false;
}

const validateToken = (next) => {
    axios.post(process.env.VUE_APP_API + "api/token/verify/", {token: router.app.$store.getters["auth/token"]})
        .then(() => {
            next();
        })
        .catch(() => {
            refreshToken(next);
        })
}

const refreshToken = (next) => {

    axios.post(process.env.VUE_APP_API + "api/token/refresh/", {token: router.app.$store.getters["auth/token"]})
        .then(response => {
            router.app.$store.dispatch("auth/setAccessToken", response.data.token)
            localStorage.setItem('access_token', response.data.token);
            next();
        })
        .catch(() => {
            next({name: "Login"});
        })
}

export default router
