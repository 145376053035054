<template>
  <v-app>
    <drawer v-if="isLogged"></drawer>

    <v-main>
      <v-snackbar
          :color="snackbarConfig.type"
          :value="snackbar"
          top
          vertical
          @input="snackbar = false"
          :timeout="snackbarConfig.timeout"
      >
        {{ snackbarConfig.message }}
        <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>

      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import Drawer from "@/components/Drawer";

export default {
  name: 'App',
  components: {
    Drawer
  },
  data() {
    return {
      snackbar: false,
      snackbarConfig: {
        message: null,
        type: null,
        timeout: null,
      },
    }
  },
  watch: {
    snackbar(newVal) {
      if (!newVal) {
        this.snackbarConfig.message = null;
        this.snackbarConfig.type = null;
      }
    }
  },
  provide() {
    return {
      showSnackbar: this.showSnackbar
    };
  },
  methods: {
    showSnackbar(type, message, timeout = 5000) {
      this.snackbarConfig.message = message;
      this.snackbarConfig.type = type;
      this.snackbar = true;
      this.snackbarConfig.timeout = timeout;
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/token"] !== null;
    }
  },
};
</script>
