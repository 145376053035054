<template>
  <v-dialog
      v-model="dialog"
      :overlay="false"
      max-width="500px"
      persistent
      transition="dialog-transition"
  >
    <v-card>
      <v-card-title primary-title>
        {{ title }}
      </v-card-title>

      <v-divider></v-divider>

      <slot name="body">
      </slot>
      <v-form @submit.stop.prevent="acceptDialog">
        <slot name="form">
        </slot>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="needClose" @click="closeDialog">Cerrar</v-btn>
        <v-btn v-if="isForm" ref="btnAcceptForm" color="primary" type="submit" @click="acceptDialog">Aceptar</v-btn>
        <v-btn ref="btnAccept" v-else color="primary" @click="acceptDialog">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    needClose: Boolean,
    isForm: {
      type: Boolean,
      default: false
    },
    closeOnAccept: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.$emit("close");
    },
    openDialog() {
      this.dialog = true
    },
    acceptDialog() {
      this.$emit("accept");
      this.dialog = !this.closeOnAccept;
    },
    enterKeyAccept() {
      if (this.isForm) {
        this.$refs.btnAcceptForm.click(this.acceptDialog)
      } else {
        this.$refs.btnAccept.click(this.acceptDialog)
      }
    }
  },

};
</script>
