<template>
  <v-card pa-5>
    <v-card-title primary-title> Pareto de Problemas {{ currentYear }}</v-card-title>
    <canvas ref="myParetoChart" width="500" height="300"></canvas>
  </v-card>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    dataChartParent: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.createChart();
  },
  data() {
    return {
      paretoLabels: null,
      dataPts: null,
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    createChart() {
      this.dataChartParent.datasets[0].data.sort((a, b) => a.time - b.time).reverse();
      this.paretoLabels = this.dataChartParent.datasets[0].data.map(item => item.label);
      this.dataPts = this.dataChartParent.datasets[0].data.map(item => item.time);

      let totalSum = this.dataPts.reduce((sum, datapoint) => sum + datapoint);
      let sum = 0;
      let cumulativeDataPoints = this.dataPts.map(
          ((sum = 0), (n) => (sum += n))
      );
      let percentageDataPoints = cumulativeDataPoints.map((value) => {
        return parseFloat((value / totalSum) * 100).toFixed(1);
      });
      const data = {
        labels: this.paretoLabels,
        datasets: [
          {
            type: "line",
            label: "Porcentaje acumulado",
            data: percentageDataPoints,
            backgroundColor: "rgba(255, 26, 104, 0.2)",
            borderColor: "rgba(255, 26, 104, 1)",
            fill: false,
            borderWidth: 1,
            yAxisID: "y2",
          },
          {
            type: "bar",
            label: "Tiempo perdido (min)",
            data: this.dataPts,
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
            barPercentage: 0.2,
            categoryPercentage: 1,
            yAxisID: "y1",
          },
        ],
      };

      const config = {
        type: "line",
        data: data,
        options: {
          responsive: true,
          scales: {
            xAxes: {
              title: {
                display: true,
                text: "Problemas contados",
              },
            },
            yAxes: [
              {
                type: "linear",
                position: "left",
                beginAtZero: true,
                min: 0,
                max: 100,
                ticks: {
                  callback: function (value) {
                    return value + "%";
                  },
                  beginAtZero: true

                },
                title: {
                  display: true,
                  text: "Porcentaje acumulado",
                },
                id: "y2",
              },
              {
                position: "right",
                beginAtZero: true,
                grid: {
                  display: false,
                },
                title: {
                  display: true,
                  text: "Tiempo total perdido",
                },
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                id: "y1",
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },

        },
      };
      new Chart(this.$refs.myParetoChart, {...config});
    },
  },
};
</script>
