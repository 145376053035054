<template>
  <v-container class="d-flex justify-end flex-column">
    <v-tabs
        v-model="tabs"
        centered
        fixed-tabs
        @change="changeTab"
    >
      <v-tab>
        Auditoría
      </v-tab>
      <v-tab>
        Historial
      </v-tab>
    </v-tabs>

    <v-card>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-row>
            <v-col>
              <v-subheader class="big-subtitle">
                Auditoria
              </v-subheader>
            </v-col>
          </v-row>
          <v-data-table
              :headers="tableHeaders"
              :items="items"
              :items-per-page="-1"
              hide-default-footer
          >
            <template v-slot:item.note="props">
              <v-edit-dialog
                  :return-value.sync="props.item.note"
                  cancel-text="Cancelar"
                  large
                  persistent
                  save-text="Guardar"
              >
                <div class="min-square-size">{{ props.item.note }}</div>
                <template v-slot:input>
                  <div class="mt-4 text-h6">
                    Agregar nota
                  </div>
                  <v-text-field
                      v-model="props.item.note"
                      :rules="[max100chars]"
                      autofocus
                      counter
                      label="Nota"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.grade="props">
              <v-edit-dialog
                  :return-value.sync="props.item.grade"
                  cancel-text="Cancelar"
                  large
                  persistent
                  save-text="Guardar"
              >
                <template v-slot:input>
                  <div class="mt-4 text-h6">
                    Calificar
                  </div>
                  <v-select
                      v-model="props.item.grade"
                      :items="grades"
                      autofocus
                      label="Valor"
                  ></v-select>
                </template>
              </v-edit-dialog>

              <v-chip
                  :color="gradeColor(props.item.grade)"
                  dark
              >
                {{ props.item.grade }}
              </v-chip>
            </template>


          </v-data-table>
          <v-row class="mt-3">
            <v-col>
              <v-text-field
                  v-model="leader"
                  autofocus
                  class="mr-5 ml-5"
                  label="Lider de la linea"
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="auditor"
                  class="mr-5 ml-5"
                  label="Auditor"
                  @keyup.enter="$refs.finish.click(finishAudit)"
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn ref="finish" color="primary" @click="finishAudit">Terminar Auditoría</v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid>
            <v-simple-table
                fixed-header
                height="300px"
            >
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Criterio
                  </th>
                  <th v-for="header in headers" :key="header" class="text-left">
                    {{ header }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, idx) in historyData"
                    :key="idx"
                >
                  <td>{{ formattedRow(item).question }}</td>
                  <td v-for="(grade, idx) in formattedRow(item).grades" :key="idx">
                    <v-chip
                        :color="gradeColor(grade)"
                        dark
                    >
                      {{ grade }}
                    </v-chip>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="text-center">
              <v-pagination
                  v-model="currentPage"
                  :length="totalPages"
                  next-icon="mdi-menu-right"
                  prev-icon="mdi-menu-left"
                  @input="changePage"
                  @next="changePage(currentPage + 1)"
                  @previous="changePage(currentPage - 1)"
              ></v-pagination>
            </div>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import {AuditGradeEnum} from "@/enums";

export default {
  name: "Gemba",
  inject: ["showSnackbar"],
  data() {
    return {
      tabs: null,
      items: [],
      max100chars: v => v.length <= 100 || 'No puedes exceder 100 caracteres.',
      grades: Object.values(AuditGradeEnum),
      leader: null,
      auditor: null,
      currentPage: 1,
      pageSize: 7,
      totalPages: 0,
      totalItems: 0,
      historyData: [],
      tableHeaders: [
        {
          text: 'Punto a auditar',
          sortable: false,
          value: 'acceptance',
        },
        {
          text: 'Cumple',
          sortable: false,
          value: 'grade',
        },
        {
          text: 'Nota',
          sortable: false,
          value: 'note',
        },
      ]
    }
  },
  computed: {
    gradeColor() {
      return (item) => {
        if (item === AuditGradeEnum.OK) {
          return "#4CAF50";
        } else if (item === AuditGradeEnum.NA) {
          return "#FF9800";
        } else if (item === AuditGradeEnum.WRONG) {
          return "#F44336";
        } else {
          return "transparent";
        }
      }
    },
    headers() {
      if (this.historyData.length > 0)
        return this.historyData[0].map(item => item.date);

      return []
    },
    formattedRow() {
      return (item) => {
        if (item === null || item === undefined) {
          return null;
        }
        return {
          question: item[0].question,
          grades: item.map(val => val.grade)
        }
      }
    },
    lane() {
      return this.$store.getters["auth/lane"]
    },
  },
  methods: {
    getAcceptanceCriteria() {
      axios.get(process.env.VUE_APP_APIURL + "acceptance/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.items = response.data.map(item => {
              return {
                acceptance: item.name,
                acceptance_id: item.id,
                grade: null,
                note: null
              }
            });
          })
    },
    finishAudit() {
      if (this.leader === null) {
        this.showSnackbar("error", "El nombre del lider de la línea es necesario.");
        return;
      }

      if (this.auditor === null) {
        this.showSnackbar("error", "El nombre del auditor es necesario.");
        return;
      }

      const data = {
        lane_id: this.lane,
        leader: this.leader,
        auditor: this.auditor,
        questions: this.items
      };

      axios.post(process.env.VUE_APP_APIURL + "gemba/", data, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.showSnackbar("success", "Registro creado correctamente");
            this.leader = this.auditor = null;
            this.getAcceptanceCriteria();
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          });
    },
    changeTab() {
      if (this.tabs === 1) {
        this.getHistoricalData()
      }
    },
    changePage(value) {
      if (value > this.totalPages || value < 1) {
        return;
      }

      this.currentPage = value;
      this.getHistoricalData();
    },
    getHistoricalData() {
      axios.get(process.env.VUE_APP_APIURL + `gemba/${this.lane}/${this.pageSize}/${this.currentPage}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.historyData = response.data.items;
            this.totalPages = response.data.pages;
            this.totalItems = response.data.total;
          })
    },
    onLoadComponent() {
      this.getAcceptanceCriteria();
    }
  },
  watch: {
    lane(newValue, oldValue) {
      this.onLoadComponent();
    },
  },
  mounted() {
    this.onLoadComponent();
  }
}
</script>

<style scoped>
.big-subtitle {
  font-size: 32px;
  margin: 20px;
}

.min-square-size {
  min-height: 24px !important;
  min-width: 24px !important;
}
</style>