<template>
  <v-container class="d-flex justify-end flex-column">
    <v-subheader class="mb-4 mt-4" style="font-size: 24px">Modelos</v-subheader>
    <deletable-table :headers="headers"
                     :items="items"
                     btn-text="Agregar Modelo"
                     deletable
                     @create="onCreateModel"
                     @delete="confirmDelete"
    >
    </deletable-table>

    <dialog-form ref="deleteModal" need-close title="¿Estas Seguro(a)?" @accept="deleteModel">
      <template v-if="itemToDelete" v-slot:body>
        <v-container>
          <p class="m-4">
            Estas a punto de eliminar el modelo "{{ itemToDelete.name }}" del listado.
          </p>
        </v-container>
      </template>
    </dialog-form>

    <dialog-form ref="createRegisterModal" need-close title="Creación de nuevo modelo"
                 @accept="createModel">
      <template v-slot:form>
        <v-container class="m-4">
          <v-text-field
              v-model="modelToCreate"
              label="Modelo"
              outlined
          ></v-text-field>
        </v-container>
      </template>
    </dialog-form>
  </v-container>
</template>

<script>
import DeletableTable from "@/components/DeletableTable";
import DialogForm from "@/components/DialogForm";
import axios from "axios";

export default {
  name: "Model",
  inject: ["showSnackbar"],
  components: {
    DeletableTable,
    DialogForm,
  },
  data() {
    return {
      headers: [
        {text: 'Modelo', value: 'name', sortable: false}
      ],
      items: [],

      itemToDelete: null,
      modelToCreate: null
    }
  },
  methods: {
    getModelTableData() {
      axios.get(process.env.VUE_APP_APIURL + "model/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.items = response.data;
          })
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.$refs.deleteModal.openDialog();
    },
    deleteModel() {
      axios.delete(process.env.VUE_APP_APIURL + "model/" + this.itemToDelete.id, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getModelTableData();
            this.showSnackbar("success", "Registro eliminado correctamente");
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          })
      this.itemToDelete = null;
    },
    onCreateModel() {
      this.$refs.createRegisterModal.openDialog();
    },
    createModel() {
      if (this.isValidModel(this.modelToCreate)) {
        const data = {
          name: this.modelToCreate
        }

        axios.post(process.env.VUE_APP_APIURL + "model/", data, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
            .then(response => {
              this.showSnackbar("success", "Registro creado correctamente");
              this.getModelTableData();
            })
            .catch(error => {
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
            })
      } else {
        this.showSnackbar("error", "El modelo no puede estar vacío");
      }

      this.modelToCreate = null;
    },
    isValidModel(model) {
      return !(model === null || model.match(/^ *$/) !== null);
    }
  },
  mounted() {
    this.getModelTableData();
  }
}
</script>

<style scoped>

</style>