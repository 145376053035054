<template>
  <v-container class="d-flex justify-end flex-column">
    <v-card color="basil elevation-0">
      <v-card-title class="text-center justify-center py-6">
        <h1 class="font-weight-bold text-h4 basil--text">Tiempo Perdido</h1>
      </v-card-title>

      <v-tabs v-model="tab" background-color="transparent" color="basil" grow @change="getChartData">
        <v-tab v-for="item in items" :key="item.value">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, index) in items" :key="index">
          <v-card>
            <v-container class="grey lighten-5">
              <v-card class="pa-2" outlined tile>
                <bar-chart-charts :key="chartData.labels.length" :dataChartParent="chartData" style="max-height: 70vh"
                                  @giveIndexColumn="showTable = !showTable"/>
                <template v-if="showTable">
                  <chart-table v-if="tab === 0" :data="tableData" :month="currentMonth" :week="getCurrentWeek"
                               @expandedDay="getTableData"></chart-table>

                  <chart-table-week v-if="tab === 1" :data="tableData" @expandedDay="getTableData">
                  </chart-table-week>

                  <chart-table-year v-if="tab === 2" :data="tableData" @expandedDay="getTableData">
                  </chart-table-year>
                </template>
              </v-card>
              <pareto-chart v-if="paretoData" :dataChartParent="paretoData" class="mt-5"/>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import BarChartCharts from "@/components/BarChartCharts";
import ChartTable from "@/components/ChartTable";
import ChartTableWeek from "@/components/ChartTableWeek";
import ChartTableYear from "@/components/ChartTableYear";
import ParetoChart from "@/components/ParetoChart";
import FrequencyEnum from "@/enums/modules/FrequencyEnum";
import axios from "axios";

export default {
  name: "Charts",
  components: {
    BarChartCharts,
    ChartTable,
    ChartTableWeek,
    ChartTableYear,
    ParetoChart,
  },
  data() {
    return {
      tab: 0,
      items: [
        {
          name: "Semanal",
          value: FrequencyEnum.WEEKLY
        },
        {
          name: "Mensual",
          value: FrequencyEnum.MONTHLY
        },
        {
          name: "Anual",
          value: FrequencyEnum.YEARLY
        }],
      chartData: null,
      tableData: [],
      paretoData: null,
      listIssuesDay: [],
      today: new Date(),
      showTable: false,
      currentMonth: new Date().getMonth(),
    };
  },
  methods: {
    getChartData() {
      axios.get(process.env.VUE_APP_APIURL + `issueSummary/${this.items[this.tab].value}/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            const data = {
              labels: response.data.map((day) => day.label),
              datasets: [
                {
                  label: "Tiempo Perdido",
                  backgroundColor: "#f87979",
                  data: response.data.map((day) => day.count),
                },
              ],
            }

            this.chartData = {...data};
          })
    },
    getTableData(data) {
      if (data === undefined) return;
      const queryDate = `${data.date.getFullYear()}-${data.date.getMonth() + 1}-${data.date.getDate()}`;
      axios.get(process.env.VUE_APP_APIURL + `issueSummaryDate/${this.lane}/${queryDate}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}}).then(response => {
        this.tableData = response.data;
      })
    },
    getParetoData() {
      axios.get(process.env.VUE_APP_APIURL + `issueDistribution/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            const data = {
              datasets: [
                {
                  label: "Tiempo Perdido",
                  backgroundColor: "#f87979",
                  data: response.data.map((day) => {
                    return {
                      time: day.time,
                      label: day.issue__name
                    }
                  }),
                },
              ],
            }

            this.paretoData = {...data};
          })
    },
    getCurrentWeek() {
      const d = new Date();
      const date = d.getDate();
      const day = d.getDay();
      const weekOfMonth = Math.ceil((date - 1 - day) / 7);
      return weekOfMonth
    },
    onLoadComponent() {
      this.getChartData();
      this.getParetoData();
    }
  },
  computed: {
    lane() {
      return this.$store.getters["auth/lane"]
    },
  },
  watch: {
    lane(newValue, oldValue) {
      this.onLoadComponent();
    },
  },
  mounted() {
    this.onLoadComponent();
  }
}
</script>

<style scoped>

</style>