<template>
  <div>
    <v-data-table
        :expanded.sync="expanded"
        :headers="realHeaders"
        :items="items"
        :single-expand="true"
        class="elevation-1"
        hide-default-footer
        :show-expand="expandable"
        @item-expanded="loadData"
        :items-per-page="-1"
    >
      <template v-slot:item.real="{ item }">
        <v-chip
            :color="realColumnColor(item)"
            dark
        >
          {{ item.real ? item.real : 0 }}
        </v-chip>

      </template>
      <template v-slot:item.data-table-expand="{expand, isExpanded}">
        <v-btn color="transparent" elevation="0" @click="expand(!isExpanded)">
          <!--
          <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
          -->
          +
        </v-btn>
      </template>
      <template v-if="expandable" v-slot:expanded-item="{ headers, item }">


        <td v-if="item.real < item.goal && item.end_hour <= currentHour && item.id !== computedCurrentSchedule"
            :colspan="headers.length">
          <v-form @submit.stop.prevent="sendForm(item)">
            <v-row class="mt-2">
              <v-col cols="12" sm="6">
                <v-select
                    v-if="expandedItemProblem === null"
                    v-model="problem"
                    :items="problems"
                    item-text="name"
                    item-value="id"
                    label="Problema"
                    outlined
                ></v-select>
                <v-text-field
                    v-else
                    v-model="expandedItemProblem"
                    :disabled="expandedItemProblem !== null"
                    label="Problema"
                    outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                    v-model="expandedItemTime ? expandedItemTime:wasted"
                    :disabled="expandedItemTime !== null"
                    :items="wastedTime"
                    label="Tiempo Perdido"
                    outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-0 pt-0">
              <v-col cols="12">
                <v-text-field
                    v-model="expandedItemAction ? expandedItemAction:action"
                    :disabled="expandedItemAction !== null"
                    label="Acción"
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="!(expandedItemAction||expandedItemTime||expandedItemProblem)" class="mt-0 pt-0 mb-1">
              <v-col class="d-flex justify-end">
                <v-btn
                    class="mr-4"
                    color="info"
                    type="submit"
                >
                  Guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "HourTable",
  inject: ["showSnackbar"],
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    problems: {
      type: Array,
      required: false
    },
    wastedTime: {
      type: Array,
      required: false
    },
    expandable: {
      type: Boolean,
      default: false
    },
    expandedItemProblem: {
      type: String,
      default: null
    },
    expandedItemAction: {
      type: String,
      default: null
    },
    expandedItemTime: {
      type: Number,
      default: null
    },
    currentSchedule: {
      type: Number | null,
      default: null
    },
  },
  computed: {
    computedCurrentSchedule() {
      return this.currentSchedule;
    },
    realColumnColor() {
      return (item) => {
        const date = new Date();
        const midnightHours = ["00:00:00", "01:00:00", "02:00:00", "03:00:00", "04:00:00", "05:00:00"]
        // No madrugada, meta mayor a real y hora menor a la actual
        if (item.real < item.goal && item.start_hour < this.currentHour && !midnightHours.includes(item.start_hour)) {
          return '#F44336';
        } else if (item.real < item.goal && item.start_hour < this.currentHour && midnightHours.includes(`${('0' + date.getHours()).slice(-2)}:00:00`)) { // Madrugada, horas anteriores a la actual y real menor a meta
          return '#F44336';
        } else if (item.real < item.goal && item.start_hour > "06:00:00" && midnightHours.includes(`${('0' + date.getHours()).slice(-2)}:00:00`)) { // madrugada, horas mayores a la madrugada (ej. 18:00 - 23:00) y meta mayor a real
          return '#F44336';
        } else {
          return '#4CAF50';
        }
      }
    }
  },
  data() {
    return {
      expanded: [],
      problem: null,
      wasted: null,
      action: null,
      realHeaders: [],
      midnightHours: ["00:00:00", "01:00:00", "02:00:00", "03:00:00", "04:00:00", "05:00:00"],
      currentHour: `${('0' + new Date().getHours()).slice(-2)}:${new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes()}:00`,
    }
  },
  methods: {
    sendForm(item) {
      if (this.isValidData()) {
        this.$emit('input', {
          issue_id: this.problem,
          time_lost: this.wasted,
          action: this.action,
          schedule_id: item.id
        });

      }
    },
    clearForm() {
      this.problem = null;
      this.wasted = null;
      this.action = null;
    },
    isValidData() {
      if (!this.problem) {
        this.showSnackbar("error", "Debes seleccionar un problema");
        return false;
      }
      if (!this.wasted) {
        this.showSnackbar("error", "Debes seleccionar la cantidad de tiempo perdido");
        return false;
      }
      if (!this.action) {
        this.showSnackbar("error", "Debes poner la acción implementada");
        return false;
      }
      return true;
    },
    loadData({item}) {
      this.$emit("itemExpanded", item);
      this.clearForm();
    }
  },
  watch: {
    currentSchedule(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentHour = `${('0' + new Date().getHours()).slice(-2)}:${new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes()}:00`;
        this.$forceUpdate();
      }
    }
  },
  mounted() {
    this.realHeaders = [...this.headers];
    this.realHeaders.push({text: '', value: 'data-table-expand'})
  }
}
</script>

<style scoped>

</style>