import Vue from 'vue'
import {store} from './store/index';
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axiosPlugin from '@/plugins/axios'


Vue.config.productionTip = false

Vue.use(axiosPlugin)

new Vue({
  router,
  vuetify,
  store: store,
  render: h => h(App)
}).$mount('#app')
