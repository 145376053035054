<template>
  <v-navigation-drawer
      app
      expand-on-hover
      mini-variant
      permanent
      :touchless="true"
  >
    <v-list>
      <!-- User Info -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ username }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ lane }} - {{ hour }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- Menu Subtitle -->
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>
            Menú
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list
        dense
        nav
    >
      <v-list-item :to="{ name: 'Hour' }" link>
        <v-list-item-icon>
          <v-icon>mdi-clipboard-text-clock-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Hora por hora</v-list-item-title>
      </v-list-item>
      <v-list-item link :to="{ name: 'Charts' }">
        <v-list-item-icon>
          <v-icon>mdi-finance</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Gráficos</v-list-item-title>
      </v-list-item>
      <v-list-item link :to="{ name: 'Metrics' }">
        <v-list-item-icon>
          <v-icon>mdi-counter</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Métricos</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'Gemba' }" link>
        <v-list-item-icon>
          <v-icon>mdi-shield-bug-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>GEMBA</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'Security' }" link>
        <v-list-item-icon>
          <v-icon>mdi-shield-check-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Seguridad</v-list-item-title>
      </v-list-item>

      <template v-if="showSupervisor">
        <v-list-item :to="{ name: 'FiveS' }" link>
          <v-list-item-icon>
            <v-icon>mdi-calendar-multiple-check</v-icon>
          </v-list-item-icon>
          <v-list-item-title>5S's</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'FiveW' }" link>
          <v-list-item-icon>
            <v-icon>mdi-account-question-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>5W's</v-list-item-title>
        </v-list-item>

        <v-list-item link :to="{ name: 'AcceptanceCriteria' }">
          <v-list-item-icon>
            <v-icon>mdi-medal-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Criterios de aceptación</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'Problems' }">
          <v-list-item-icon>
            <v-icon>mdi-alert</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Problemas</v-list-item-title>
        </v-list-item>
      </template>

      <template v-if="showAdmin">
        <v-list-item link :to="{name: 'TurnsView'}">
          <v-list-item-icon>
            <v-icon>mdi-autorenew</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Turnos</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Lane' }" link>
          <v-list-item-icon>
            <v-icon>mdi-ray-start-vertex-end</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Lineas</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'Users' }">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Usuarios</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Notification' }" link>
          <v-list-item-icon>
            <v-icon>mdi-bell-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Notificaciones</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'Model' }" link>
          <v-list-item-icon>
            <v-icon>mdi-archive-eye-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Modelos</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'BusinessUnits' }" link>
          <v-list-item-icon>
            <v-icon>mdi-office-building-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Unidades de negocio</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block @click="logout()">
          <v-icon left>mdi-logout</v-icon>
          Cerrar Sesion
        </v-btn>
      </div>
      <v-select
          v-if="showSupervisor"
          v-model="selectedLane"
          :items="lanes"
          item-text="name"
          item-value="id"
          filled
          @change="updateSelectedLane"
      ></v-select>

    </template>


  </v-navigation-drawer>
</template>

<script>
import {UserTypeEnum} from "@/enums";
import axios from "axios";

export default {
  name: "Drawer",
  inject: ["showSnackbar"],
  computed: {
    lane() {
      return this.$store.getters['auth/laneName'];
    },
    showSupervisor() {
      return this.$store.getters['auth/userType'] !== UserTypeEnum.PRODUCTION
    },
    showAdmin() {
      return this.$store.getters['auth/userType'] === UserTypeEnum.ADMIN;
    },
    currentLane() {
      return this.$store.getters['auth/lane']
    },
    currentSchedule() {
      return this.$store.getters["hour/currentSchedule"];
    }
  },
  data() {
    return {
      username: this.$store.getters['auth/username'],
      usertype: this.$store.getters['auth/userType'],
      selectedLane: null,
      hour: null,
      lanes: [],
    }
  },
  methods: {
    updateHour() {
      const today = new Date();
      if (today.getMinutes() < 10) {
        this.hour = today.getHours() + ":0" + today.getMinutes();
      } else {
        this.hour = today.getHours() + ":" + today.getMinutes();
      }

      if (today.getMinutes() % 6 === 0 && today.getSeconds() < 3) {
        this.getCurrentShift();
      }

      if (today.getMinutes() % 1 === 0 && today.getSeconds() < 3) {
        this.getCurrentSchedule();
      }

      if ((today.getHours() === 8 || today.getHours() === 20) && (today.getMinutes() === 0 || today.getMinutes() === 5)
          && today.getSeconds() < 7) {
        this.showSnackbar("warning", "No olvides realizar el checklist", -1);
      }
    },
    getLanes() {
      axios.get(process.env.VUE_APP_APIURL + "lanes/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.$store.dispatch("lanes/loadLanes", response.data)
            this.lanes = this.$store.getters["lanes/lanes"];
            this.$store.dispatch('auth/changeLane', this.$store.getters["auth/lane"]);
            this.$store.dispatch('auth/changeLaneName', this.lanes.find(lane => lane.id === this.$store.getters["auth/lane"]).name);
            this.selectedLane = this.lanes.find(lane => lane.id === this.$store.getters["auth/lane"])
          })
    },
    updateSelectedLane() {
      this.$store.dispatch('auth/changeLane', this.selectedLane);
      this.$store.dispatch('auth/changeLaneName', this.lanes.find(lane => lane.id === this.selectedLane).name);
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({name: 'Login'})
    },
    getCurrentShift() {
      axios.get(process.env.VUE_APP_APIURL + "currentShift/" + this.currentLane, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            if (this.$store.getters["auth/shift"] === response.data.shift_id) {

            } else {

              this.$store.dispatch("hour/unblockAddButton");
              this.$store.dispatch("auth/setShift", response.data.shift_id)
              this.$store.dispatch("hour/updateCurrentSchedule", null)
            }
            this.getCurrentSchedule();
          }).catch(error => {
        this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
      })
    },
    getCurrentSchedule() {
      axios.get(process.env.VUE_APP_APIURL + "currentSchedule/" + this.currentLane, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            if (this.$store.getters["hour/currentSchedule"] === response.data.id) {

            } else {
              this.$store.dispatch("hour/updateCurrentSchedule", response.data.id)
            }
          }).catch(error => {
        this.$store.dispatch("hour/updateCurrentSchedule", null)
        this.showSnackbar("error", error.response.data.message ? error.response.data.message : "No se pudo encontrar horario activo.");
      })
    }
  },
  watch: {},
  created() {
    setInterval(this.updateHour, 3000);
  },
  mounted() {
    if (this.usertype === UserTypeEnum.ADMIN || this.usertype === UserTypeEnum.SUPERVISOR) {
      this.getLanes();
    }
    this.getCurrentShift();
  }
}
</script>

<style scoped>

</style>