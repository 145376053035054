<template>
  <v-container>
    <v-subheader style="font-size: 24px" class="mb-4 mt-4">
      5S's Mensual
    </v-subheader>
    <!-- 5s SEMANAL -->
    <v-container>
      <v-data-table
          :headers="headersWeek"
          :items="itemsWeeks"
          class="elevation-1"
          disable-sort
          hide-default-footer
      >
        <!-- Header Slot -->

        <!-- ITEMS SLOTS -->
        <template v-slot:item.weekOne="{ item }">
          <v-chip :color="getColor(item.weekOne)">
            {{ item.weekOne }}
          </v-chip>
        </template>
        <template v-slot:item.weekTwo="{ item }">
          <v-chip :color="getColor(item.weekTwo)">
            {{ item.weekTwo }}
          </v-chip>
        </template>
        <template v-slot:item.weekThree="{ item }">
          <v-chip :color="getColor(item.weekThree)">
            {{ item.weekThree }}
          </v-chip>
        </template>
        <template v-slot:item.weekFour="{ item }">
          <v-chip :color="getColor(item.weekFour)">
            {{ item.weekFour }}
          </v-chip>
        </template>
        <template v-slot:item.weekFive="{ item }">
          <v-chip :color="getColor(item.weekFive)">
            {{ item.weekFive }}
          </v-chip>
        </template>
        <template v-slot:item.average="{ item }">
          <p>
            {{ item.average }}
          </p>
        </template>
      </v-data-table>
      <v-row class="mt-2 d-flex justify-end">
        <v-btn color="primary" @click="addRegister"
        >Agregar registro
        </v-btn
        >
      </v-row>
    </v-container>

    <DialogForm
        ref="createRegisterModal"
        :needClose="true"
        title="Agregar Nuevo Registro"
        @accept="submitForm()"
    >
      <template v-slot:form>
        <v-container class="m-4">
          <v-form ref="addTurnRegisterForm">
            <v-text-field
                v-model="inputWeek"
                :rules="addTurnRegisterRules"
                label="Semana"
                outlined
                type="number"
            >
            </v-text-field>
            <v-text-field
                v-for="(turn, index) in turns"
                :key="index"
                v-model="inputTurn[index]"
                :label="`Turno ${turn}`"
                outlined
                type="number"
                @keyup.enter="enterKey"
            ></v-text-field>
          </v-form>
        </v-container>
      </template>
    </DialogForm>


    <v-subheader style="font-size: 24px" class="mb-4 mt-4">
      5S's Anual
    </v-subheader>
    <!-- 5S ANUAL -->
    <v-container :key="fiveYearKey">
      <v-data-table
          :headers="headerYear"
          :items="itemsYear"
          :items-per-page="12"
          class="elevation-1"
          hide-default-footer
      >
        <template v-slot:item.average="{ item }">
          <v-chip :color="getColor(item.average)" dark>
            {{ item.average }}
          </v-chip>
        </template>
      </v-data-table>
    </v-container>

    <ActionTable
        :actionsData="actionsTableData"
        @createAction="postMetricAction"
        @editItem="onEditAction"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import ActionTable from "@/components/ActionTable.vue";
import DialogForm from "@/components/DialogForm.vue";
import {MetricsEnum} from "@/enums";

export default {
  inject: ["showSnackbar"],
  data() {
    return {
      fiveYearKey: 1,
      responseWeeks: [],
      responseYear: [],
      showRegisterModal: false,
      showActionModal: false,
      turnsInWeeks: [],
      inputWeek: "",
      inputTurn: [],
      addTurnRegisterRules: [(v) => !!v || "No puede estar vacío"],
      actionsTableData: [],
      turns: [],
      turnsId: [],
      headersWeek: [
        {
          text: "Turno",
          value: "shift__name"
        },
        {
          text: "Promedio",
          value: "average"
        }
      ],
      itemsWeeks: [],
      headerYear: [],
      itemsYear: []

    };
  },
  methods: {
    enterKey() {
      this.$refs.createRegisterModal.enterKeyAccept()
    },
    getColor(weekValue) {
      if (!weekValue) {
        return "white";
      }
      if (weekValue < 4) return "red";
      return "green";
    },
    getTurnFromData(arr) {
      let resp = {...arr};
      resp = Object.entries(resp);
      let turns = resp.map((week, index, array) => {
        return week[1];
      });

      const weekProp = [
        "weekOne",
        "weekTwo",
        "weekThree",
        "weekFour",
        "weekFive",
      ];
      let arrResp = [];
      let weekNumber = 0;

      for (const week of turns) {
        let turnIndex = 0;

        for (const turn of week) {
          turnIndex++;
          if (arrResp.length === 0) {
            arrResp.push({
              weekOne: null,
              weekTwo: null,
              weekThree: null,
              weekFour: null,
              weekFive: null,
              turn: turn.shift__name,
            });
          } else if (
              arrResp.filter((e) => e.turn === turn.shift__name)
                  .length <= 0
          ) {
            arrResp.push({
              weekOne: null,
              weekTwo: null,
              weekThree: null,
              weekFour: null,
              weekFive: null,
              turn: turn.shift__name,
            });
          }
        }

        for (let index = 0; index < week.length; index++) {
          const indArr = arrResp.findIndex(
              (trn) => trn.turn === week[index].shift__name
          );
          const propInObject = weekProp[weekNumber];
          arrResp[indArr][propInObject] = week[index].value;
        }
        weekNumber++;
      }

      weekNumber = 0;
      arrResp.forEach((element) => {
        let {weekOne, weekTwo, weekThree, weekFour, weekFive} =
            element;
        let numberOfWeeksWithValue = [
          weekOne,
          weekTwo,
          weekThree,
          weekFour,
          weekFive,
        ];
        let filtered = numberOfWeeksWithValue.filter(function (el) {
          return el != null;
        });
        let avrg =
            (weekOne + weekTwo + weekThree + weekFour + weekFive) /
            filtered.length;
        element["average"] = avrg;
      });

      return arrResp;
    },
    loadDataWeeks() {
      axios
          .get(process.env.VUE_APP_APIURL + `5s/mensual/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then((response) => {
            if (Object.keys(response.data).length !== 0) {
              this.responseWeeks = {...response.data}
              this.getWeekItems()
            }
          });
    },
    loadDataYear() {
      axios
          .get(process.env.VUE_APP_APIURL + `5s/anual/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then((response) => {
            this.responseYear = {...response.data};
            this.updateHeadersYear()
            this.updateItemsYears()
          });
    },
    loadDataActionTable() {
      axios
          .get(
              process.env.VUE_APP_APIURL +
              `metricAction/${MetricsEnum.S5}/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}}
          )
          .then((response) => {
            this.actionsTableData = [...response.data];
          });
    },
    addRegister() {
      this.$refs.createRegisterModal.openDialog();
    },
    getWeekItems() {
      let headers = [
        {
          text: "Turno",
          align: "start",
          sortable: false,
          value: "turn",
        },
      ];
      const nameColumns = [
        "weekOne",
        "weekTwo",
        "weekThree",
        "weekFour",
        "weekFive",
      ];
      const numberOfWeeks = Object.keys(this.responseWeeks).length;
      let arr = Object.keys(this.responseWeeks);
      for (let index = 0; index < numberOfWeeks; index++) {
        headers.push({
          text: `Semana ${arr[index]}`,
          sortable: false,
          value: nameColumns[index],
        });
      }
      headers.push({
        text: `Promedio`,
        value: "average",
      });

      this.headersWeek = [...headers]

      let aux = this.getTurnFromData(this.responseWeeks)
      this.itemsWeeks = [...aux]

    },
    getHeadersYear(arr) {
      let response = {...arr};
      response = Object.entries(response);
      let months = response.map((month, index) => {
        return month[1];
      });
      const filteredMonths = months.filter((month) => {
        return month.length > 0;
      });

      let headers = [];

      for (const month of filteredMonths) {
        for (const turn of month) {
          if (headers.length === 0) {
            headers.push({
              text: "Mes",
              align: "start",
              sortable: false,
              value: "month",
            });
            headers.push({
              text: `Turno ${turn.shift__name}`,
              align: "start",
              sortable: false,
              value: turn.shift__name,
            });
          } else if (
              headers.filter(
                  (e) => e.text === `Turno ${turn.shift__name}`
              ).length <= 0
          ) {
            headers.push({
              text: `Turno ${turn.shift__name}`,
              align: "start",
              sortable: false,
              value: turn.shift__name,
            });
          }
        }
      }

      headers.push({
        text: "Promedio",
        align: "start",
        sortable: false,
        value: "average",
      });
      return headers;
    },
    getDataYear(arr) {
      const nameOfMonths = {
        January: "Enero",
        February: "Febrero",
        March: "Marzo",
        April: "Abril",
        May: "Mayo",
        June: "Junio",
        July: "Julio",
        August: "Agosto",
        September: "Septiembre",
        October: "Octubre",
        November: "Noviembre",
        December: "Diciembre",
      };

      let response = {...arr};
      response = Object.entries(response);

      let headers = this.getHeadersYear(arr);
      let turnsInResponse = headers.map((a) => a.value);
      turnsInResponse.shift();
      let data = [];
      let auxOriginal = {...arr};

      for (let index = 0; index < response.length; index++) {
        if (response[index][1].length === 0) {
          data.push({
            month: nameOfMonths[response[index][0]],
          });
          continue;
        }

        let base = {
          month: nameOfMonths[response[index][0]],
        };

        response[index][1].forEach((element, i) => {
          base[turnsInResponse[i]] = element.value;
          if (data.indexOf(base) < 0) {
            data.push(base);
          }
        });
      }

      data.forEach((element) => {
        if (Object.keys(element).length > 1) {
          let {month, ...numbers} = element;
          const lengthOfData = Object.keys(numbers).length;
          const values = Object.values(numbers);
          const sum = values.reduce((accumulator, value) => {
            return accumulator + value;
          }, 0);
          element.average = sum / lengthOfData;
        }
      });

      return data;
    },
    submitForm() {
      this.isValidRegister();
    },
    isValidRegister() {
      if (this.inputWeek.length > 0) {
        let data = {
          lane_id: this.lane,
          week: this.inputWeek,
          items: [],
        };
        this.inputTurn.forEach((valor, turno) => {
          data.items.push({
            "shift_id": this.turnsId[turno],
            "value": valor
          })
        });
        axios.post(process.env.VUE_APP_APIURL + '5s/', data, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}}).then((resp) => {
          this.showSnackbar("success", "Registro exitoso");
          this.loadDataWeeks()
          this.loadDataYear()
        })

        this.inputTurn = []
      } else {
        this.showSnackbar("error", "La semana no puede estar vacía");
      }
    },
    onEditAction(item) {
      axios
          .put(`${process.env.VUE_APP_APIURL}metricAction/`, item, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then((response) => {
            this.showSnackbar(
                "success",
                "Registro actualizado correctamente"
            );
            this.updateHeadersYear()
            this.updateItemsYears()
          });
    },
    postMetricAction(item) {
      if (this.shift === null || this.shift === undefined) {
        this.showSnackbar("error", "No puedes crear acción sin turno activo.");
        return;
      }
      item["lane_id"] = this.lane;
      item["shift_id"] = this.shift;
      item["metric"] = MetricsEnum.S5;
      axios
          .post(`${process.env.VUE_APP_APIURL}metricAction/`, item, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then((response) => {
            this.showSnackbar(
                "success",
                "Registro creado correctamente"
            );
            this.onLoadComponent();
          })
          .catch((e) => this.showSnackbar("error", e));
    },
    getDataTurns() {
      try {
        axios
            .get(process.env.VUE_APP_APIURL + "shifts/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
            .then((res) => {
              this.turns = [...res.data];
              this.turnsId = this.turns.map((a) => a.id);
              this.turns = this.turns.map((a) => a.name);
            });
      } catch (error) {
        this.showSnackbar("error", error);
      }
    },
    updateHeadersYear() {
      if (!this.responseYear) {
        this.headerYear = []
      } else {
        this.headerYear = this.getHeadersYear(this.responseYear);
      }
    },
    updateItemsYears() {
      if (!this.responseYear) {
        this.itemsYear = []
      } else {
        let results = this.getDataYear(this.responseYear);
        results = results.reverse();
        this.itemsYear = results
      }
    },
    onLoadComponent() {
      this.getDataTurns();
      this.loadDataWeeks();
      this.loadDataYear();
      this.loadDataActionTable();
    }
  },
  computed: {
    lane() {
      return this.$store.getters["auth/lane"]
    },
    shift() {
      return this.$store.getters["auth/shift"]
    },
    getNumericKeys() {
      return (item) => {
        let itemKeys = Object.keys(item);
        if (itemKeys === null || itemKeys === undefined) {
          return [];
        }
        const index = itemKeys.indexOf("month");
        itemKeys.splice(index, 1);
        return itemKeys;
      };
    },

  },
  created() {
    this.onLoadComponent();
  },
  watch: {
    lane(newValue, oldValue) {
      this.onLoadComponent();
    },
    shift(newValue, oldValue) {
      this.onLoadComponent();
    }
  },

  components: {ActionTable, DialogForm},
};
</script>

<style></style>
