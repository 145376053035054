<template>
  <v-container class="d-flex justify-end flex-column">
    <v-subheader style="font-size: 24px" class="mb-4 mt-4">Problemas del hora por hora</v-subheader>
    <deletable-table :headers="headers"
                     :items="items"
                     btn-text="Agregar Problema"
                     deletable
                     @delete="confirmDelete"
                     @create="onCreateProblem"
    >
    </deletable-table>

    <dialog-form ref="deleteModal" need-close title="¿Estas Seguro(a)?" @accept="deleteProblem">
      <template v-if="itemToDelete" v-slot:body>
        <v-container>
          <p class="m-4">
            Estas a punto de eliminar el problema "{{ itemToDelete.name }}" del listado de problemas posibles.
          </p>
        </v-container>
      </template>
    </dialog-form>

    <dialog-form ref="createRegisterModal" need-close title="Creación de nuevo problema" @accept="createProblem">
      <template v-slot:form>
        <v-container class="m-4">
          <v-text-field
              v-model="problemToCreate"
              label="Problema"
              outlined
          ></v-text-field>
        </v-container>
      </template>
    </dialog-form>
  </v-container>
</template>

<script>
import DeletableTable from "@/components/DeletableTable";
import DialogForm from "@/components/DialogForm";
import axios from "axios";

export default {
  name: "Problems",
  inject: ["showSnackbar"],
  components: {
    DeletableTable,
    DialogForm,
  },
  data() {
    return {
      headers: [
        {text: 'Problemas', value: 'name', sortable: false}
      ],
      items: [],

      itemToDelete: null,
      problemToCreate: null
    }
  },
  methods: {
    getProblemsTableData() {
      axios.get(process.env.VUE_APP_APIURL + "issue/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.items = response.data;
          })
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.$refs.deleteModal.openDialog();
    },
    deleteProblem() {
      axios.delete(process.env.VUE_APP_APIURL + "issue/" + this.itemToDelete.id, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getProblemsTableData();
            this.showSnackbar("success", "Registro eliminado correctamente");
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          })
      this.itemToDelete = null;
    },
    onCreateProblem() {
      this.$refs.createRegisterModal.openDialog();
    },
    createProblem() {
      if (this.isValidProblem(this.problemToCreate)) {
        const data = {
          name: this.problemToCreate
        }

        axios.post(process.env.VUE_APP_APIURL + "issue/", data, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
            .then(response => {
              this.showSnackbar("success", "Registro creado correctamente");
              this.getProblemsTableData();
            })
            .catch(error => {
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
            })
      } else {
        this.showSnackbar("error", "El problema no puede estar vacío");
      }

      this.problemToCreate = null;
    },
    isValidProblem(problem) {
      return !(problem === null || problem.match(/^ *$/) !== null);
    }
  },
  mounted() {
    this.getProblemsTableData();
  }
}
</script>

<style scoped>

</style>