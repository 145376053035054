import axios from "axios";


const metrics = {
    namespaced: true,

    state: {
        actionsToMetric: [],
        qualityData: {
            speedometerData: 0,
            chartsData: [
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
            ],
        },
        deliverData: {
            speedometerData: 0,
            chartsData: [
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
            ],
        },
        scrapData: {
            speedometerData: 0,
            chartsData: [
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
            ],
        },
        oceData: {
            speedometerData: 0,
            chartsData: [
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
                {
                    labels: [],
                    datasets: [{data: [], backgroundColor: "#000"}],
                },
            ],
        },
    },
    actions: {
        async loadPercentage({commit, state}, payload) {
            if (payload.tap === 0) {
                axios.get(`${process.env.VUE_APP_APIURL}metricPercentage/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                    .then((response) => {
                        commit('setPercentageQuality', response.data.percentage);
                    })
                    .catch(() => {
                        commit('setPercentageQuality', 0);
                    })
            }
            if (payload.tap === 1) {
                axios.get(`${process.env.VUE_APP_APIURL}metricPercentage/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                    .then(response => {
                        commit('setPercentageDeliver', response.data.percentage);
                    })
                    .catch(() => {
                        commit('setPercentageDeliver', 0);
                    })
            }
            if (payload.tap === 2) {
                axios.get(`${process.env.VUE_APP_APIURL}metricPercentage/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                    .then(response => {
                        commit('setPercentageScrap', response.data.percentage)
                    })
                    .catch(() => {
                        commit('setPercentageScrap', 0);
                    })
            }
            if (payload.tap === 3) {
                axios.get(`${process.env.VUE_APP_APIURL}metricPercentage/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                    .then(response => {
                        commit('setPercentageOce', response.data.percentage)
                    })
                    .catch(() => {
                        commit('setPercentageOce', 0);
                    })
            }
        },
        async loadCharts({commit, state}, payload) {
            if (payload.tap === 0) {
                const yearData = await axios.get(`${process.env.VUE_APP_APIURL}metric/anual/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                const monthData = await axios.get(`${process.env.VUE_APP_APIURL}metric/mensual/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                const weekData = await axios.get(`${process.env.VUE_APP_APIURL}metric/semanal/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                let data = {
                    year: yearData.data,
                    month: monthData.data,
                    week: weekData.data
                }
                commit('setChartsQuality', data)
            }
            if (payload.tap === 1) {
                const yearData = await axios.get(`${process.env.VUE_APP_APIURL}metric/anual/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                const monthData = await axios.get(`${process.env.VUE_APP_APIURL}metric/mensual/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                const weekData = await axios.get(`${process.env.VUE_APP_APIURL}metric/semanal/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                let data = {
                    year: yearData.data,
                    month: monthData.data,
                    week: weekData.data
                }
                commit('setChartsDeliver', data)
            }
            if (payload.tap === 2) {
                const yearData = await axios.get(`${process.env.VUE_APP_APIURL}metric/anual/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                const monthData = await axios.get(`${process.env.VUE_APP_APIURL}metric/mensual/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                let data = {
                    year: yearData.data,
                    month: monthData.data,
                }
                commit('setChartsScrap', data)
            }
            if (payload.tap === 3) {
                const yearData = await axios.get(`${process.env.VUE_APP_APIURL}metric/anual/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                const monthData = await axios.get(`${process.env.VUE_APP_APIURL}metric/mensual/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                const weekData = await axios.get(`${process.env.VUE_APP_APIURL}metric/semanal/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                let data = {
                    year: yearData.data,
                    month: monthData.data,
                    week: weekData.data
                }
                commit('setChartsOce', data)
            }
        },
        async loadActions({commit, state}, payload) {
            axios.get(`${process.env.VUE_APP_APIURL}metricAction/${payload.metric}/${payload.lineId}`, {headers: {Authorization: `Bearer ${payload.token}`}})
                .then(response => {
                    commit('setActions', response.data);
                })
                .catch(() => {
                    commit('setActions', []);
                })
        },
        async doCreateAction() {

        },
        async doAddProblem() {

        }
    },
    mutations: {
        setPercentageQuality(state, value) {
            state.qualityData.speedometerData = value
        },
        setPercentageDeliver(state, value) {
            state.deliverData.speedometerData = value
        },
        setPercentageScrap(state, value) {
            state.scrapData.speedometerData = value
        },
        setPercentageOce(state, value) {
            state.oceData.speedometerData = value
        },
        setChartsQuality(state, value) {
            let getFields = (input, field) => {
                var output = [];
                for (var i = 0; i < input.length; ++i)
                    output.push(input[i][field]);
                return output;
            }

            let labelsY = getFields(value.year, 'label')
            let countsY = getFields(value.year, 'count')
            let yearNewValues = {
                labels: labelsY,
                datasets: [
                    {
                        data: countsY,
                        backgroundColor: "#000"
                    }
                ],
            }
            let labelsM = getFields(value.month, 'label')
            let countsM = getFields(value.month, 'count')
            let monthNewValues = {
                labels: labelsM,
                datasets: [
                    {
                        data: countsM,
                        backgroundColor: "#000"
                    }
                ],
            }
            let labelsW = getFields(value.week, 'label')
            let countsW = getFields(value.week, 'count')
            let weekNewValues = {
                labels: labelsW,
                datasets: [
                    {
                        data: countsW,
                        backgroundColor: "#000"
                    }
                ],
            }
            state.qualityData.chartsData = [yearNewValues, monthNewValues, weekNewValues]
        },
        setChartsDeliver(state, value) {
            let getFields = (input, field) => {
                var output = [];
                for (var i = 0; i < input.length; ++i)
                    output.push(input[i][field]);
                return output;
            }

            let labelsY = getFields(value.year, 'label')
            let countsY = getFields(value.year, 'count')
            let yearNewValues = {
                labels: labelsY,
                datasets: [
                    {
                        data: countsY,
                        backgroundColor: "#000"
                    }
                ],
            }
            let labelsM = getFields(value.month, 'label')
            let countsM = getFields(value.month, 'count')
            let monthNewValues = {
                labels: labelsM,
                datasets: [
                    {
                        data: countsM,
                        backgroundColor: "#000"
                    }
                ],
            }
            let labelsW = getFields(value.week, 'label')
            let countsW = getFields(value.week, 'count')
            let weekNewValues = {
                labels: labelsW,
                datasets: [
                    {
                        data: countsW,
                        backgroundColor: "#000"
                    }
                ],
            }

            state.deliverData.chartsData = [yearNewValues, monthNewValues, weekNewValues];
        },
        setChartsScrap(state, value) {
            let getFields = (input, field) => {
                var output = [];
                for (var i = 0; i < input.length; ++i)
                    output.push(input[i][field]);
                return output;
            }

            let labelsY = getFields(value.year, 'label')
            let countsY = getFields(value.year, 'count')
            let yearNewValues = {
                labels: labelsY,
                datasets: [
                    {
                        data: countsY,
                        backgroundColor: "#000"
                    }
                ],
            }
            let labelsM = getFields(value.month, 'label')
            let countsM = getFields(value.month, 'count')
            let monthNewValues = {
                labels: labelsM,
                datasets: [
                    {
                        data: countsM,
                        backgroundColor: "#000"
                    }
                ],
            }

            state.scrapData.chartsData = [yearNewValues, monthNewValues]
        },
        setChartsOce(state, value) {
            let getFields = (input, field) => {
                var output = [];
                for (var i = 0; i < input.length; ++i)
                    output.push(input[i][field]);
                return output;
            }

            let labelsY = getFields(value.year, 'label')
            let countsY = getFields(value.year, 'count')
            let yearNewValues = {
                labels: labelsY,
                datasets: [
                    {
                        data: countsY,
                        backgroundColor: "#000"
                    }
                ],
            }
            let labelsM = getFields(value.month, 'label')
            let countsM = getFields(value.month, 'count')
            let monthNewValues = {
                labels: labelsM,
                datasets: [
                    {
                        data: countsM,
                        backgroundColor: "#000"
                    }
                ],
            }
            let labelsW = getFields(value.week, 'label')
            let countsW = getFields(value.week, 'count')
            let weekNewValues = {
                labels: labelsW,
                datasets: [
                    {
                        data: countsW,
                        backgroundColor: "#000"
                    }
                ],
            }

            state.oceData.chartsData = [yearNewValues, monthNewValues, weekNewValues]
        },
        setActions(state, value) {
            state.actionsToMetric = value;
        }
    },
    getters: {
        //Speedometer data
        getSpeedometerQuality: (state) => {
            return state.qualityData.speedometerData;
        },
        getSpeedometerDeliver: (state) => {
            return state.deliverData.speedometerData;
        },
        getSpeedometerScrap: (state) => {
            return state.scrapData.speedometerData;
        },
        getSpeedometerOce: (state) => {
            return state.oceData.speedometerData;
        },

        //Charts data
        getChartsDataQuality: (state) => {
            return state.qualityData.chartsData;
        },
        getChartsDataDeliver: (state) => {
            return state.deliverData.chartsData;
        },
        getChartsDataScrap: (state) => {
            return state.scrapData.chartsData;
        },
        getChartsDataOce: (state) => {
            return state.oceData.chartsData;
        },

        //Actions Data
        getActionsData: (state) => {
            return state.actionsToMetric;
        },
    },
};

export default metrics;
