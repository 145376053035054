import {UserTypeEnum} from "@/enums";

const state = () => ({
    accessToken: null, // makes sure the user is logged in even after
    userType: UserTypeEnum.ADMIN,
    lane: 1,
    username: "Lider Linea 12",
    shift: 1,
    laneName: "",
})

// getters
const getters = {
    token(state) {
        return state.accessToken;
    },
    userType(state) {
        return state.userType;
    },
    username(state) {
        return state.username;
    },
    lane(state) {
        return state.lane;
    },
    laneName(state) {
        return state.laneName;
    },
    shift(state) {
        return state.shift;
    }
}

// actions
const actions = {
    changeLane({commit}, lane) {
        commit('changeLane', lane);
    },
    changeLaneName({commit}, laneName) {
        commit('changeLaneName', laneName);
    },
    setUsername({commit}, username) {
        commit('setUsername', username);
    },
    setUserType({commit}, userType) {
        commit('setUserType', userType);
    },
    setAccessToken({commit}, token) {
        commit('setAccessToken', token);
    },
    logout({commit}) {
        commit("logout")
    },
    setShift({commit}, shiftId) {
        commit("setShift", shiftId)
    }
}

// mutations
const mutations = {
    changeLane(state, lane) {
        state.lane = lane;
    },
    changeLaneName(state, laneName) {
        state.laneName = laneName;
    },
    setUsername(state, username) {
        state.username = username;
    },
    setUserType(state, userType) {
        state.userType = userType;
    },
    setAccessToken(state, token) {
        state.accessToken = token;
    },
    logout(state) {
        state.accessToken = null;
        state.username = null;
        state.userType = null;
        state.laneName = null;
        state.lane = null;
        state.shift = null;
    },
    setShift(state, shiftId) {
        state.shift = shiftId;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}