<template>
  <div>
    <canvas ref="myChart" width="500" height="300" :key="chartKey"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  data() {
    return {
      chartKey: 0,
    };
  },
  props: {
    chartData: {type: Object, required: true},
    tooltipText: {
      type: String,
      default: "%"
    }
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      //Mount Chart
      const columnItem = this.columnItem
      const myChartObject = new Chart(this.$refs.myChart, {
        type: "bar",
        data: {
          labels: this.chartData.labels,
          datasets: [
            {
              label: this.tooltipText,
              data: this.chartData.datasets[0].data,
              borderColor: "rgba(50, 115, 220, 0.5)",
              backgroundColor: this.chartData.datasets[0].backgroundColor,
            },
          ],
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
              },
            },],
          },
          onClick(e) {
            var activePoints = myChartObject.getElementsAtEvent(e);
            columnItem(activePoints)
          }
        }
      });
    },
    columnItem(activePoints) {
      this.$emit('giveIndexColumn', activePoints);
    }
  },
};
</script>
