import { render, staticRenderFns } from "./Lane.vue?vue&type=template&id=056d2804&scoped=true&"
import script from "./Lane.vue?vue&type=script&lang=js&"
export * from "./Lane.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056d2804",
  null
  
)

export default component.exports