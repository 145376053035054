<template>
  <v-container class="d-flex justify-end flex-column">
    <v-row>
      <v-col md="6" sm="12">
        <v-img
            :lazy-src="logo"
            :src="logo"
            max-height="150"
            max-width="200"
        ></v-img>
      </v-col>
      <v-col class="d-flex justify-end" md="6" sm="12">
        <div class="bordered">
          <v-container d-flex>
            <v-col class="no-padding">
              <v-container class="pa-2 colored gray-colored">
                <p>
                  Contador
                </p>
                <p class="big-text ">
                  Dias sin incidencias
                </p>
              </v-container>
            </v-col>
            <v-col class="no-padding">
              <p class="big-number colored d-flex align-items-center justify-center">
                {{ noIncidenceDays }}
              </p>
            </v-col>
          </v-container>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12">
        <v-sheet height="540">
          <v-calendar
              ref="calendar"
              :event-color="gradeColor"
              :events="events"
              event-more-text="Y más"
              locale="es"
              show-month-on-first
              type="month"
          ></v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-end" cols="12">
        <v-btn color="primary" @click="onAddIncident">Agregar incidente</v-btn>
      </v-col>
    </v-row>
    <dialog-form ref="createRegisterModal" need-close title="Registrar incidente" @accept="createRegister">
      <template v-slot:form>
        <v-container class="m-4">
          <v-select
              v-model="newRegisterLevel"
              :items="accidentLevels"
              label="Nivel de gravedad"
              outlined
          ></v-select>
        </v-container>
      </template>
    </dialog-form>
  </v-container>
</template>

<script>
import {IncidentLevelEnum} from "@/enums";
import axios from "axios";
import DialogForm from "@/components/DialogForm";
import logo from "../assets/logo.jpg"

export default {
  name: "Security",
  components: {
    DialogForm
  },
  inject: ["showSnackbar"],
  computed: {
    gradeColor() {
      return (item) => {
        if (item && item.name === IncidentLevelEnum.MILD) {
          return "#FF9800";
        } else if (item && item.name === IncidentLevelEnum.SERIOUS) {
          return "#F44336";
        } else {
          return "#4CAF50";
        }
      }
    }
  },
  data() {
    return {
      newRegisterLevel: null,
      accidentLevels: Object.values(IncidentLevelEnum),
      events: [],
      incidents: [],
      lane: this.$store.getters["auth/lane"],
      logo: logo,
      noIncidenceDays: null
    }
  },
  methods: {
    createRegister() {
      if (this.newRegisterLevel === null) {
        this.showSnackbar("error", "Es necesario seleccionar un valor.");
        return;
      }

      axios.post(process.env.VUE_APP_APIURL + `security/`, {
        incident_level: this.newRegisterLevel,
        lane_id: this.lane
      }, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {

            this.newRegisterLevel = null;
            this.showSnackbar("success", "Incidente registrado");
            this.getIncidents();
            this.getDaysWithoutIncidents();
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado");
          })
          .finally(() => {
            this.$refs.createRegisterModal.closeDialog();
          })
    },
    getIncidents() {
      axios.get(process.env.VUE_APP_APIURL + `security/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}}).then(response => {
        this.incidents = response.data;
        this.createEvents(response.data);
      })
    },
    createEvents(incidents) {
      const date = new Date();
      let events = [];
      let month = null;

      if (date.getMonth() + 1 < 10) {
        month = "0" + (date.getMonth() + 1).toString();
      } else {
        month = (date.getMonth() + 1).toString();
      }

      for (let i = 1; i <= date.getDate(); i++) {
        let obj = incidents.filter(item => item.date === `${date.getFullYear()}-${month}-${i < 10 ? '0' + i : i}`)

        if (obj && obj.length > 0) {
          obj.forEach(item => {
            events.push({
              name: item.incident_level,
              start: item.date,
              end: item.date,
            })
          })
        } else {
          events.push({
            name: null,
            start: `${date.getFullYear()}-${date.getMonth() + 1}-${i < 10 ? '0' + i : i}`,
            end: `${date.getFullYear()}-${date.getMonth() + 1}-${i < 10 ? '0' + i : i}`,
          })
        }
      }

      this.events = events;
    },
    onAddIncident() {
      this.$refs.createRegisterModal.openDialog();
    },
    getDaysWithoutIncidents() {
      axios.get(process.env.VUE_APP_APIURL + `daysWithoutIncident/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}}).then(response => {
        this.noIncidenceDays = response.data.days;
        this.createEvents(response.data);
      })
    },
  },
  mounted() {
    this.getIncidents();
    this.getDaysWithoutIncidents();
  }
}
</script>

<style scoped>
.bordered {
  max-width: 300px;
  min-width: 200px;
  min-height: 50px;
  border-radius: 10px;
}

.big-number {
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: white;
  margin: 0;
}

.big-text {
  font-style: normal;
  margin: 0;
  font-weight: 400;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 0.25px;
}

.colored {
  padding: 0;
  background: #1976D2;
  border-radius: 0 10px 10px 0;
  width: 100%;
  height: 100%;
  align-items: center;
}

.gray-colored {
  background: #D9D9D9;
  border-radius: 10px 0 0 10px;
}

.no-padding {
  padding: 0;
}
</style>