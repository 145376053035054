<template>
  <v-container class="d-flex justify-end flex-column">
    <v-subheader class="mb-4 mt-4" style="font-size: 24px">Usuarios</v-subheader>
    <deletable-table :headers="headers"
                     :items="items"
                     btn-text="Agregar Usuario"
                     deletable
                     @delete="confirmDelete"
                     @create="onCreateRegister"
    >
    </deletable-table>

    <dialog-form ref="deleteModal" need-close title="¿Estas Seguro(a)?" @accept="deleteRegister">
      <template v-if="itemToDelete" v-slot:body>
        <v-container>
          <p class="m-4">
            Estas a punto de eliminar al usuario "{{ itemToDelete.username }}".
          </p>
        </v-container>
      </template>
    </dialog-form>

    <dialog-form ref="createRegisterModal" need-close title="Creación de nuevo usuario" @accept="createRegister" @keyup.enter="createRegisterModal.accept">
      <template v-slot:form>
        <v-container class="m-4">
          <v-text-field
              v-model="itemToCreate.username"
              label="Nombre de usuario"
              outlined
          ></v-text-field>
          <v-select
              v-model="itemToCreate.type"
              :items="userTypes"
              label="Rol"
              outlined
          ></v-select>
          <v-select
              v-model="itemToCreate.lane"
              :items="lanes"
              item-text="name"
              item-value="id"
              label="Linea asignada"
              outlined
          ></v-select>
          <v-text-field
              v-model="itemToCreate.password"
              label="Contraseña"
              outlined
              type="password"
          ></v-text-field>
          <v-text-field
              v-model="itemToCreate.confirm_password"
              label="Confirmar Contraseña"
              outlined
              type="password"
          ></v-text-field>
        </v-container>
      </template>
    </dialog-form>
  </v-container>
</template>

<script>
import DialogForm from "@/components/DialogForm";
import DeletableTable from "@/components/DeletableTable";
import {UserTypeEnum} from "@/enums";
import axios from "axios";

export default {
  name: "Users",
  inject: ["showSnackbar"],
  components: {
    DeletableTable,
    DialogForm,
  },
  data() {
    return {
      headers: [
        {text: 'Nombre de usuario', value: 'username', sortable: false},
        {text: 'Rol', value: 'type', sortable: false},
        {text: 'Linea asignada', value: 'lane', sortable: false},
      ],
      items: [],
      userTypes: Object.values(UserTypeEnum),

      itemToDelete: null,
      itemToCreate: {
        username: null,
        type: null,
        lane: null,
        password: null,
        confirm_password: null
      },

      lanes: []
    }
  },
  methods: {
    getTableData() {
      axios.get(process.env.VUE_APP_APIURL + "users", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.items = response.data;
          })
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.$refs.deleteModal.openDialog();
    },
    deleteRegister() {
      axios.delete(process.env.VUE_APP_APIURL + "users/" + this.itemToDelete.user_id, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getTableData();
            this.showSnackbar("success", "Registro eliminado correctamente");
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          })
      this.itemToDelete = null;
    },
    onCreateRegister() {
      this.$refs.createRegisterModal.openDialog();
    },
    createRegister() {
      if (this.validatePasswords()) {
        axios.post(process.env.VUE_APP_APIURL + "users/", this.itemToCreate, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
            .then(response => {
              this.showSnackbar("success", "Registro creado correctamente");
              this.getTableData();
            })
            .catch(error => {
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
            })
        this.itemToCreate = {
          username: null,
          type: null,
          lane: null,
          password: null,
          confirm_password: null
        };
      } else {
        if (this.itemToCreate.password === this.itemToCreate.confirm_password) {
          this.showSnackbar("error", "La contraseña es menor a 8 caracteres");
        } else {
          this.showSnackbar("error", "Las contraseñas no son iguales.");
        }
        this.itemToCreate = {
          username: null,
          type: null,
          lane: null,
          password: null,
          confirm_password: null
        };
      }
    },
    validatePasswords() {
      if (this.itemToCreate.password === this.itemToCreate.confirm_password && this.itemToCreate.password.length > 7) {
        return true
      } else {
        return false;
      }
    },
    getLanes() {
      axios.get(process.env.VUE_APP_APIURL + "lanes/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.$store.dispatch("lanes/loadLanes", response.data)
            this.lanes = this.$store.getters["lanes/lanes"];
            this.$store.dispatch('auth/changeLane', this.$store.getters["auth/lane"]);
            this.$store.dispatch('auth/changeLaneName', this.lanes.find(lane => lane.id === this.$store.getters["auth/lane"]).name);
            this.selectedLane = this.lanes.find(lane => lane.id === this.$store.getters["auth/lane"])
          })
    },
  },
  mounted() {
    this.getTableData();
    this.getLanes()
  }
}
</script>

<style scoped>

</style>