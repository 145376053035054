const state = () => ({
    currentSchedule: null,
    blockAddButton: false,
    model: null,
    businessUnit: null
})

// getters
const getters = {
    currentSchedule(state) {
        return state.currentSchedule;
    },
    blockAddButton(state) {
        return state.blockAddButton;
    },
    model(state) {
        return state.model;
    },
    businessUnit(state) {
        return state.businessUnit;
    },
}

// actions
const actions = {
    updateCurrentSchedule({commit}, currentSchedule) {
        commit("updateCurrentSchedule", currentSchedule)
    },
    blockAddButton({commit}) {
        commit("updateBlockButton", true)
    },
    unblockAddButton({commit}) {
        commit("updateBlockButton", false)
    },
    updateModel({commit}, model) {
        commit("updateModel", model);
    },
    updateBusinessUnit({commit}, businessUnit) {
        commit("updateBusinessUnit", businessUnit);
    }
}

// mutations
const mutations = {
    updateCurrentSchedule(state, currentSchedule) {
        state.currentSchedule = currentSchedule;
    },
    updateBlockButton(state, value) {
        state.blockAddButton = value;
    },
    updateModel(state, model) {
        state.model = model;
    },
    updateBusinessUnit(state, businessUnit) {
        state.businessUnit = businessUnit;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}