<template>
  <v-container class="d-flex justify-end flex-column">
    <v-subheader style="font-size: 24px" class="mb-4 mt-4"
    >5W's (5 ¿Por qué?'s)
    </v-subheader
    >

    <v-card>
      <v-data-table
          :headers="headers"
          :hide-default-footer="true"
          :items="items"
          disable-sort
          @click:row="itemClicked"
      >
      </v-data-table>
    </v-card>
    <v-row class="ma-5" align-content-end>
      <v-spacer></v-spacer>
      <v-btn class="ma-4" color="primary" @click="onCreateReport">Agregar Reporte</v-btn>
    </v-row>

    <v-dialog width="500px" v-model="dialog">
      <v-toolbar color="white">
        <v-card-title class="text-h5 bg-white">
          Registrar nuevo 5W's
        </v-card-title>
      </v-toolbar>
      <v-stepper v-model="stepper" color="green">
        <v-stepper-header class="overflow-x-hidden">
          <v-stepper-step
              v-for="(step, n) in steps"
              :key="n"
              :color="stepStatus(n + 1)"
              :complete="stepComplete(n + 1)"
              :rules="[(value) => !!step.valid]"
              :step="n + 1"
          >
            {{ step.name }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-content
            v-for="(stepSteps, n) in steps"
            :key="n"
            :step="n + 1"
        >
          {{ stepSteps.name }}
          <v-card color="grey lighten-5" class="mb-12">
            <v-card-text v-if="n === 3">
              <v-form
                  :ref="'stepForm'"
                  v-model="stepSteps.valid"
                  lazy-validation
              >
                <v-text-field
                    v-model="stepSteps.formData.title"
                    :rules="stepSteps.rules"
                    label="Titulo"
                ></v-text-field>
                <v-text-field
                    v-model="stepSteps.formData.area"
                    :rules="stepSteps.rules"
                    label="Area"
                ></v-text-field>
                <v-text-field
                    v-model="stepSteps.formData.supervisor"
                    :rules="stepSteps.rules"
                    label="supervisor"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-text v-else>
              <v-form
                  :ref="'stepForm'"
                  v-model="stepSteps.valid"
                  lazy-validation
              >
                <v-text-field
                    v-model="stepSteps.formData.q1"
                    :rules="stepSteps.rules"
                    label="1er ¿Por que? "
                ></v-text-field>
                <v-text-field
                    v-model="stepSteps.formData.q2"
                    :rules="stepSteps.rules"
                    label="2do ¿Por que? "
                ></v-text-field>
                <v-text-field
                    v-model="stepSteps.formData.q3"
                    :rules="stepSteps.rules"
                    label="3er ¿Por que? "
                ></v-text-field>
                <v-text-field
                    v-model="stepSteps.formData.q4"
                    :rules="stepSteps.rules"
                    label="4to ¿Por que? "
                ></v-text-field>
                <v-text-field
                    v-model="stepSteps.formData.q5"
                    :rules="stepSteps.rules"
                    label="5to ¿Por que? "
                ></v-text-field>
                <v-text-field
                    v-model="stepSteps.formData.root"
                    :rules="stepSteps.rules"
                    @keyup.enter="enterRoot(n)"
                    label="Raiz"
                ></v-text-field>
              </v-form>
            </v-card-text>
          </v-card>
          <v-btn
              v-if="n + 1 < lastStep"
              :disabled="!stepSteps.valid"
              ref="btn"
              color="primary"
              @click="validate(n)"
          >Continue
          </v-btn
          >
          <v-btn v-else color="primary" @click="validateFinal(n)"
          >Registrar
          </v-btn
          >
          <v-btn v-if="n !== 0" text @click="stepper = stepper - 1"
          >Atras
          </v-btn
          >
          <v-btn color="secondary" text @click="closeDialog">Cerrar</v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-dialog>

    <dialog-form
        ref="clickedRowDialog"
        :title="`Detalles ${rowSelected.title}`"
        need-close
        @close="closeSelectedRow"
    >
      <template #body v-if="isRowSelected">
        <v-card>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Supervisor</v-list-item-title>
              <v-list-item-subtitle>{{
                  rowSelected.supervisor
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>Area</v-list-item-title>
              <v-list-item-subtitle>{{
                  rowSelected.area
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>Fecha</v-list-item-title>
              <v-list-item-subtitle>{{
                  rowSelected.date
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>

        <v-tabs fixed-tabs background-color="primary" dark>
          <v-tab> Predicción</v-tab>
          <v-tab> Protección</v-tab>
          <v-tab> Prevención</v-tab>

          <v-tab-item>
            <v-card>
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header
                  >Primer ¿Por qué?
                  </v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    {{ rowSelected.prediction.first }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                  >Segundo ¿Por qué?
                  </v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    {{ rowSelected.prediction.second }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                  >Tercero ¿Por qué?
                  </v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    {{ rowSelected.prediction.third }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                  >Cuarto ¿Por qué?
                  </v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    {{ rowSelected.prediction.forth }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                  >Quinto ¿Por qué?
                  </v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    {{ rowSelected.prediction.fifth }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header
                  >Raiz
                  </v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    {{ rowSelected.prediction.root_cause }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-expansion-panel-header
                >Primer ¿Por qué?
                </v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  {{ rowSelected.prevention.first }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                >Segundo ¿Por qué?
                </v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  {{ rowSelected.prevention.second }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                >Tercero ¿Por qué?
                </v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  {{ rowSelected.prevention.third }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                >Cuarto ¿Por qué?
                </v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  {{ rowSelected.prevention.forth }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                >Quinto ¿Por qué?
                </v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  {{ rowSelected.prevention.fifth }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                >Raiz
                </v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  {{ rowSelected.prevention.root_cause }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>
          <v-tab-item>
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-expansion-panel-header
                >Primer ¿Por qué?
                </v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  {{ rowSelected.protection.first }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                >Segundo ¿Por qué?
                </v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  {{ rowSelected.protection.second }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                >Tercero ¿Por qué?
                </v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  {{ rowSelected.protection.third }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                >Cuarto ¿Por qué?
                </v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  {{ rowSelected.protection.forth }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                >Quinto ¿Por qué?
                </v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  {{ rowSelected.protection.fifth }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                >Raiz
                </v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  {{ rowSelected.protection.root_cause }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>
        </v-tabs>
      </template>
    </dialog-form>
  </v-container>
</template>

<script>
import DeletableTable from "@/components/DeletableTable";
import DialogForm from "@/components/DialogForm";
import axios from "axios";

export default {
  inject: ["showSnackbar"],
  components: {
    DeletableTable,
    DialogForm,
  },
  data() {
    return {

      headers: [
        {text: "Reporte", value: "title", sortable: false},
        {text: "Fecha", value: "date"},
      ],
      stepper: 1,
      lastStep: 4,
      items: [],
      steps: [
        {
          name: "Predicción",
          rules: [(v) => !!v || "Required."],
          valid: true,
          formData: {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            root: "",
          },
        },
        {
          name: "Protección",
          rules: [(v) => !!v || "Required."],
          valid: true,
          formData: {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            root: "",
          },
        },
        {
          name: "Prevención",
          rules: [(v) => !!v || "Required."],
          valid: true,
          formData: {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            root: "",
          },
        },
        {
          name: "Informacion General",
          rules: [(v) => !!v || "Required."],
          valid: true,
          formData: {
            title: "",
            area: "",
            supervisor: "",
          },
        },
      ],
      valid: false,
      stepForm: [],
      stepperValid: false,
      dialog: false,
      dialogReport: false,
      rowSelected: {},
      isRowSelected: false,
    };
  },
  methods: {
    enterRoot(n) {
      this.$refs.btn.click(this.validate(n));
    },
    getWhysTableData() {
      axios
          .get(process.env.VUE_APP_APIURL + "whys/" + this.lane, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then((response) => {
            this.items = [...response.data];
          });
    },
    onCreateReport() {
      this.dialog = true;
    },
    createReport(payload) {
      if (this.stepperValid) {
        try {
          axios
              .post(process.env.VUE_APP_APIURL + "whys/", payload, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
              .then((response) => {
                this.showSnackbar("success", "Nuevo Reporte Registrado Correctamente");
                this.getWhysTableData();
                this.cleanForms();
                this.dialog = false;
              });
        } catch (error) {
          this.showSnackbar("error", error);
        }
      } else {
        this.showSnackbar("error", "Formulario incompleto");
      }
    },
    stepComplete(step) {
      return this.stepper > step;
    },
    stepStatus(step) {
      return this.stepper > step ? "green" : "blue";
    },
    validate(n) {
      this.steps[n].valid = false;
      let v = this.$refs.stepForm[n].validate();
      if (v) {
        this.steps[n].valid = true;
        // continue to next
        this.stepper = n + 2;
      }
    },
    validateFinal(n) {
      this.steps[n].valid = false;
      let v = this.$refs.stepForm[n].validate();
      if (v) {
        this.steps[n].valid = true;
        // continue to next
        let payload = {
          lane_id: this.lane,
          title: this.steps[3].formData.title,
          area: this.steps[3].formData.area,
          supervisor: this.steps[3].formData.supervisor,
          prediction: {
            q1: this.steps[0].formData.q1,
            q2: this.steps[0].formData.q2,
            q3: this.steps[0].formData.q3,
            q4: this.steps[0].formData.q4,
            q5: this.steps[0].formData.q5,
            root: this.steps[0].formData.root,
          },
          protection: {
            q1: this.steps[1].formData.q1,
            q2: this.steps[1].formData.q2,
            q3: this.steps[1].formData.q3,
            q4: this.steps[1].formData.q4,
            q5: this.steps[1].formData.q5,
            root: this.steps[1].formData.root,
          },
          prevention: {
            q1: this.steps[2].formData.q1,
            q2: this.steps[2].formData.q2,
            q3: this.steps[2].formData.q3,
            q4: this.steps[2].formData.q4,
            q5: this.steps[2].formData.q5,
            root: this.steps[2].formData.root,
          },
        };
        this.stepperValid = true;
        this.createReport(payload);
      }
    },
    done() {
      this.stepperValid = true;
      this.stepper = 0;
    },
    closeDialog() {
      this.cleanForms();
      this.dialog = false;
    },
    cleanForms() {
      this.steps = [
        {
          name: "Predicción",
          rules: [(v) => !!v || "Required."],
          valid: true,
          formData: {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            root: "",
          },
        },
        {
          name: "Protección",
          rules: [(v) => !!v || "Required."],
          valid: true,
          formData: {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            root: "",
          },
        },
        {
          name: "Prevención",
          rules: [(v) => !!v || "Required."],
          valid: true,
          formData: {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            root: "",
          },
        },
        {
          name: "Informacion General",
          rules: [(v) => !!v || "Required."],
          valid: true,
          formData: {
            title: "",
            area: "",
            supervisor: "",
          },
        },
      ];
      this.stepper = 1;
    },
    itemClicked(item) {
      this.$refs.clickedRowDialog.openDialog();
      this.rowSelected = {...item};
      this.isRowSelected = true;
    },
    closeSelectedRow() {
      this.isRowSelected = false;
    },
    onLoadComponent() {
      this.getWhysTableData();
    }
  },
  computed: {
    lane() {
      return this.$store.getters["auth/lane"]
    },
    shift() {
      return this.$store.getters["auth/shift"]
    },
  },
  watch: {
    lane(newValue, oldValue) {
      this.onLoadComponent();
    },
    shift(newValue, oldValue) {
      this.onLoadComponent();
    }
  },
  mounted() {
    this.onLoadComponent();
  },
};
</script>

<style scoped>
</style>