<template>
  <v-container>
    <v-row justify="center">
      <!-- Tabs Menu -->
      <v-tabs
          v-model="selectedTab"
          centered
          color="primary"
          slider-color="primary"
      >
        <v-tab> CALIDAD</v-tab>
        <v-tab> ENTREGA</v-tab>
        <v-tab> SCRAP</v-tab>
        <v-tab> OCE</v-tab>
      </v-tabs>

      <!-- Tabs Content -->
      <v-tabs-items v-show="chartValues" v-model="selectedTab">
        <!-- Quality Tab -->
        <v-tab-item>
          <QDOTabContent
              v-if="selectedTab === 0"
              :chartsData="chartValues"
              :expanded-month-data="expandedMonthData"
              :expanded-week-data="expandedWeekData"
              :expanded-year-data="expandedYearData"
              :speedDometer="speedometerValue"
              tooltipText="% FPY"
              @monthData="loadMonthData"
              @yearData="loadYearData"
          ></QDOTabContent>
        </v-tab-item>

        <!-- Delivery Tab -->
        <v-tab-item>
          <QDOTabContent
              v-if="selectedTab === 1"
              :chartsData="chartValues"
              :expanded-month-data="expandedMonthData"
              :expanded-week-data="expandedWeekData"
              :expanded-year-data="expandedYearData"
              :speedDometer="speedometerValue"
              tooltipText="% OTM"
              @monthData="loadMonthData"
              @yearData="loadYearData"
          />
        </v-tab-item>

        <!-- SCRAP Tab -->
        <v-tab-item>
          <ScrapTab
              v-if="selectedTab === 2"
              :chartsData="chartValues"
              :expanded-month-data="expandedMonthData"
              :expanded-year-data="expandedYearData"
              :speedDometer="Number(speedometerValue)"
              tooltipText="% Scrap"
              @monthData="loadMonthData"
              @yearData="loadYearData"
          ></ScrapTab>
        </v-tab-item>

        <!-- OCE Tab -->
        <v-tab-item>
          <QDOTabContent
              v-if="selectedTab === 3"
              :chartsData="chartValues"
              :expanded-month-data="expandedMonthData"
              :expanded-week-data="expandedWeekData"
              :expanded-year-data="expandedYearData"
              :speedDometer="speedometerValue"
              tooltipText="% OCE"
              @monthData="loadMonthData"
              @yearData="loadYearData"
          ></QDOTabContent>
        </v-tab-item>
      </v-tabs-items>
    </v-row>
    <v-row>
      <v-container>
        <ActionTable :actionsData="actionsDataValue" @createAction="postMetricAction"
                     @editItem="onEditAction"></ActionTable>
      </v-container>
    </v-row>

    <!-- Floating Action Btn -->
    <v-btn
        bottom
        color="primary"
        dark
        fab
        fixed
        large
        right
        @click="openAddRegister"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <dialog-form
        ref="metricRegister"
        is-form
        needClose
        title="Registro de métricos"
        @accept="acceptAddRegister"
    >
      <template v-slot:form>
        <v-container>
          <v-text-field
              v-model="acceptedPieces"
              label="Piezas aceptadas"
              required
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="numberOfPieces"
              label="Piezas Inspeccionadas"
              required
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="onTimeOrder"
              label="Ordenes en Tiempo"
              required
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="totalOrders"
              label="Ordenes Totales"
              required
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="timeLost"
              label="Tiempo Perdido (Min)"
              required
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="minutesAvailable"
              label="Minutos disponibles"
              required
              type="number"
              @keyup.enter="enterKey"
          ></v-text-field>
        </v-container>
      </template>
    </dialog-form>

    <dialog-form
        ref="scrapRegister"
        is-form
        needClose
        title="Registro de Scrap"
        @accept="postScrapRegister"
    >
      <template v-slot:form>
        <v-text-field
            v-model="scrapPercentage"
            label="% de scrap"
            required
            type="number"
        ></v-text-field>
      </template>
    </dialog-form>
  </v-container>
</template>

<script>
import QDOTabContent from "../components/QDOTabContent.vue";
import ScrapTab from "../components/ScrapTab.vue";
import DialogForm from "@/components/DialogForm.vue";
import MetricsEnum from "@/enums/modules/MetricsEnum"
import {mapActions} from "vuex";
import ActionTable from "@/components/ActionTable";
import axios from "axios";

export default {
  name: "Metrics",
  inject: ["showSnackbar"],
  data() {
    return {

      selectedTab: 0,
      acceptedPieces: 0,
      numberOfPieces: 0,
      showFloatingModal: false,
      qualityKey: 0,
      onTimeOrder: 0,
      totalOrders: 0,
      timeLost: 0,
      minutesAvailable: 0,
      scrapPercentage: 0,
      expandedYearData: [],
      expandedMonthData: [],
      expandedWeekData: [],
    };
  },
  methods: {
    enterKey() {
      this.$refs.metricRegister.enterKeyAccept()
    },
    openAddRegister() {
      if (this.selectedMetric === MetricsEnum.SCRAP) {
        this.$refs.scrapRegister.openDialog();
        return;
      }
      this.$refs.metricRegister.openDialog();
    },
    acceptAddRegister() {
      const data = {
        lane_id: this.lane,
        accepted_pieces: this.acceptedPieces,
        inspected_pieces: this.numberOfPieces,
        on_time_orders: this.onTimeOrder,
        total_orders: this.totalOrders,
        lost_time: this.timeLost,
        available_minutes: this.minutesAvailable,
        date: new Date()
      }
      axios.post(`${process.env.VUE_APP_APIURL}metric/`, data, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.showSnackbar("success", "Registro creado correctamente");
            const payload = {
              metric: MetricsEnum.QUALITY,
              lineId: this.lane,
              tap: 0,
              token: this.$store.getters["auth/token"]
            }
            this.loadPercentage(payload)
            this.loadCharts(payload)
            this.loadActions(payload)
            this.onLoadComponent();
          }).catch(error => {
        this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
      })
    },
    postScrapRegister() {
      const data = {
        lane_id: this.lane,
        end_date: new Date(),
        start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
        percentage: this.scrapPercentage,
      }
      data.start_date = `${data.start_date.getFullYear()}-${data.start_date.getMonth() + 1}-${data.start_date.getDate()}`
      data.end_date = `${data.end_date.getFullYear()}-${data.end_date.getMonth() + 1}-${data.end_date.getDate()}`

      axios.post(`${process.env.VUE_APP_APIURL}scrap/`, data, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.scrapPercentage = null;
            this.showSnackbar("success", "Registro creado correctamente");
            const payload = {
              metric: MetricsEnum.SCRAP,
              lineId: this.lane,
              tap: 2,
              token: this.$store.getters["auth/token"]
            }
            this.loadPercentage(payload)
            this.loadCharts(payload)
            this.loadActions(payload)
            this.onLoadComponent();
          }).catch(error => {
        this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
      })
    },
    loadYearData(data) {
      if (data === undefined) return;
      const queryDate = `${data.date.getFullYear()}-${data.date.getMonth() + 1}-${data.date.getDate()}`;
      axios.get(process.env.VUE_APP_APIURL + `metricByDate/${this.selectedMetric}/${this.lane}/${queryDate}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}}).then(response => {
        this.expandedYearData = response.data;
      })
    },
    loadMonthData(data) {
      if (data === undefined) return;
      const queryDate = `${data.date.getFullYear()}-${data.date.getMonth() + 1}-${data.date.getDate()}`;
      axios.get(process.env.VUE_APP_APIURL + `metricByDate/${this.selectedMetric}/${this.lane}/${queryDate}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}}).then(response => {
        this.expandedMonthData = response.data;
      })
    },
    loadWeekData(data) {
      if (data === undefined) return;
      const queryDate = `${data.date.getFullYear()}-${data.date.getMonth() + 1}-${data.date.getDate()}`;
      axios.get(process.env.VUE_APP_APIURL + `metricByDate/${this.selectedMetric}/${this.lane}/${queryDate}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}}).then(response => {
        this.expandedWeekData = response.data;
      })
    },
    postMetricAction(item) {
      if (this.shift === null || this.shift === undefined) {
        this.showSnackbar("error", "No puedes crear acción sin turno activo.");
        return;
      }
      item["lane_id"] = this.lane;
      item["shift_id"] = this.shift;
      item["metric"] = this.selectedMetric;
      axios.post(`${process.env.VUE_APP_APIURL}metricAction/`, item, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.showSnackbar("success", "Registro creado correctamente");
            const payload = {
              lineId: this.lane,
              metric: this.selectedMetric,
              tap: this.tap,
              token: this.$store.getters["auth/token"]
            }
            this.loadPercentage(payload)
            this.loadCharts(payload)
            this.loadActions(payload)
          }).catch(error => {
        this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
      })


    },
    onEditAction(item) {
      axios.put(`${process.env.VUE_APP_APIURL}metricAction/`, item, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.showSnackbar("success", "Registro actualizado correctamente");
            this.showSnackbar("success", "Registro actualizado correctamente");
            const payload = {
              metric: this.selectedMetric,
              lineId: this.lane,
              tap: this.tap,
              token: this.$store.getters["auth/token"]
            }
            this.loadPercentage(payload)
            this.loadCharts(payload)
            this.loadActions(payload)
          }).catch(error => {
        this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
      })

    },
    ...mapActions({loadPercentage: 'metrics/loadPercentage'}),
    ...mapActions({loadCharts: 'metrics/loadCharts'}),
    ...mapActions({loadActions: 'metrics/loadActions'}),
    onLoadComponent() {
      const payload = {
        metric: MetricsEnum.QUALITY,
        lineId: this.lane,
        tap: this.selectedTab,
        token: this.$store.getters["auth/token"]
      };
      this.loadPercentage(payload);
      this.loadCharts(payload);
      this.loadActions(payload);
      this.loadSummarizedData();
    },
    loadSummarizedData() {
      axios.get(process.env.VUE_APP_APIURL + `metricsSummaryData/${this.lane}/${this.shift}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.timeLost = response.data.wastedTime;
            this.minutesAvailable = response.data.availableTime;
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado");
          })
    }
  },
  computed: {
    titleTab() {
      if (this.selectedTab === 0) {
        return "Calidad";
      }
      if (this.selectedTab === 1) {
        return "Entrega";
      }
      if (this.selectedTab === 2) {
        return "Scrap";
      }
      if (this.selectedTab === 3) {
        return "OCE";
      }
    },
    speedometerValue() {
      if (this.selectedTab === 0) {
        return this.$store.getters["metrics/getSpeedometerQuality"];
      }
      if (this.selectedTab === 1) {
        return this.$store.getters["metrics/getSpeedometerDeliver"];
      }
      if (this.selectedTab === 2) {
        return this.$store.getters["metrics/getSpeedometerScrap"];
      }
      if (this.selectedTab === 3) {
        return this.$store.getters["metrics/getSpeedometerOce"];
      }
    },
    chartValues() {
      if (this.selectedTab === 0) {
        return this.$store.getters["metrics/getChartsDataQuality"];
      }
      if (this.selectedTab === 1) {
        return this.$store.getters["metrics/getChartsDataDeliver"];
      }
      if (this.selectedTab === 2) {
        return this.$store.getters["metrics/getChartsDataScrap"];
      }
      if (this.selectedTab === 3) {
        return this.$store.getters["metrics/getChartsDataOce"];
      }
    },
    actionsDataValue() {
      return this.$store.getters["metrics/getActionsData"];
    },
    selectedMetric() {
      switch (this.selectedTab) {
        case 0:
          return MetricsEnum.QUALITY;
        case 1:
          return MetricsEnum.DELIVER;
        case 2:
          return MetricsEnum.SCRAP;
        case 3:
          return MetricsEnum.OCE;
        default:
          return null;
      }
    },
    lane() {
      return this.$store.getters["auth/lane"]
    },
    shift() {
      return this.$store.getters["auth/shift"]
    },
  },
  created() {
    this.onLoadComponent();
  },
  watch: {
    selectedTab(newValue, oldValue) {
      const metricsEnm = Object.values(MetricsEnum)

      const payload = {
        metric: metricsEnm[newValue],
        lineId: this.lane,
        tap: newValue,
        token: this.$store.getters["auth/token"]
      }
      this.loadPercentage(payload)
      this.loadCharts(payload)
      this.loadActions(payload)
    },
    lane(newValue, oldValue) {
      const payload = {
        metric: this.selectedMetric,
        tap: this.tap,
        lineId: this.lane,
        token: this.$store.getters["auth/token"]
      }
      this.loadCharts(payload)
      this.onLoadComponent();
    },
    shift(newValue, oldValue) {
      const payload = {
        metric: this.selectedMetric,
        tap: this.tap,
        lineId: this.lane,
        token: this.$store.getters["auth/token"]
      }
      this.loadCharts(payload)
      this.onLoadComponent();
    }
  },
  components: {
    QDOTabContent,
    ScrapTab,
    DialogForm,
    ActionTable
  },
};
</script>

<style></style>
