<template>
  <canvas ref="myChart" width="500" height="300"></canvas>
</template>

<script>
import Chart from "chart.js";
import DataBackgroundEnum from "@/enums/modules/DataBackgroundEnum.js";
export default {
  props: {
    dataChartParent: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      animal: "asdf",
    };
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const columnItem = this.columnItem
      const myChartObject = new Chart(this.$refs.myChart, {
        type: "bar",
        data: {
          labels: this.$props.dataChartParent.labels,
          datasets: [
            {
              label: "Tiempo perdido",
              data: this.$props.dataChartParent.datasets[0].data,
              borderColor: "rgba(50, 115, 220, 0.5)",
              backgroundColor: DataBackgroundEnum.BLUE,
              
              maxBarThickness: 200
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
              },
            },],
          },
          onClick(e) {
            var activePoints = myChartObject.getElementsAtEvent(e);
            columnItem(activePoints)
          }
        }
      });
    },
    columnItem(activePoints) {
      this.$emit('giveIndexColumn', activePoints);
    }
  },
};
</script>
