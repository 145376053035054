<template>
  <v-container class="d-flex justify-end flex-column">
    <v-subheader class="mb-4 mt-4" style="font-size: 24px">Unidades de negocio</v-subheader>
    <deletable-table :headers="headers"
                     :items="items"
                     btn-text="Agregar unidad"
                     deletable
                     @create="onCreateBusinessUnit"
                     @delete="confirmDelete"
    >
    </deletable-table>

    <dialog-form ref="deleteModal" need-close title="¿Estas Seguro(a)?" @accept="deleteBusinessUnit">
      <template v-if="itemToDelete" v-slot:body>
        <v-container>
          <p class="m-4">
            Estas a punto de eliminar la unidad de negocio "{{ itemToDelete.name }}" del listado de unidades.
          </p>
        </v-container>
      </template>
    </dialog-form>

    <dialog-form ref="createRegisterModal" need-close title="Creación de nueva unidad de negocio"
                 @accept="createBusinessUnit">
      <template v-slot:form>
        <v-container class="m-4">
          <v-text-field
              v-model="businessUnitToCreate"
              label="Unidad de negocio"
              outlined
          ></v-text-field>
        </v-container>
      </template>
    </dialog-form>
  </v-container>
</template>

<script>
import DeletableTable from "@/components/DeletableTable";
import DialogForm from "@/components/DialogForm";
import axios from "axios";

export default {
  name: "BusinessUnits",
  inject: ["showSnackbar"],
  components: {
    DeletableTable,
    DialogForm,
  },
  data() {
    return {
      headers: [
        {text: 'Unidades de negocio', value: 'name', sortable: false}
      ],
      items: [],

      itemToDelete: null,
      businessUnitToCreate: null
    }
  },
  methods: {
    getBusinessUnitsTableData() {
      axios.get(process.env.VUE_APP_APIURL + "businessUnit/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.items = response.data;
          })
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.$refs.deleteModal.openDialog();
    },
    deleteBusinessUnit() {
      axios.delete(process.env.VUE_APP_APIURL + "businessUnit/" + this.itemToDelete.id, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getBusinessUnitsTableData();
            this.showSnackbar("success", "Registro eliminado correctamente");
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          })
      this.itemToDelete = null;
    },
    onCreateBusinessUnit() {
      this.$refs.createRegisterModal.openDialog();
    },
    createBusinessUnit() {
      if (this.isValidBusinessUnit(this.businessUnitToCreate)) {
        const data = {
          name: this.businessUnitToCreate
        }

        axios.post(process.env.VUE_APP_APIURL + "businessUnit/", data, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
            .then(response => {
              this.showSnackbar("success", "Registro creado correctamente");
              this.getBusinessUnitsTableData();
            })
            .catch(error => {
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
            })
      } else {
        this.showSnackbar("error", "La unidad de negocio no puede estar vacía");
      }

      this.businessUnitToCreate = null;
    },
    isValidBusinessUnit(businessUnit) {
      return !(businessUnit === null || businessUnit.match(/^ *$/) !== null);
    }
  },
  mounted() {
    this.getBusinessUnitsTableData();
  }
}
</script>

<style scoped>

</style>