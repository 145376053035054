<template>
  <v-container class="d-flex justify-end flex-column">
    <v-row>
      <v-col>
        <v-img
            :lazy-src="logo"
            :src="logo"
            max-height="75"
            max-width="125"
        ></v-img>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="2">
        <v-subheader class="big-text">
          Porcentajes:
        </v-subheader>
      </v-col>
      <v-col cols="6" md="2">
        <v-chip
            :color="percentageColor(quality)"
            class="ma-2"
            medium
        >
          {{ quality }}%
          <v-icon>mdi-clipboard-check-multiple-outline</v-icon>
        </v-chip>
      </v-col>
      <v-col cols="6" md="2">
        <v-chip
            :color="scrapPercentageColor(scrap)"
            class="ma-2"
            medium
        >
          {{ scrap }}%
          <v-icon>mdi-currency-usd</v-icon>
        </v-chip>
      </v-col>
      <v-col cols="6" md="2">
        <v-chip
            :color="percentageColor(deliver)"
            class="ma-2"
            medium
        >
          {{ deliver }}%
          <v-icon>mdi-truck-delivery</v-icon>
        </v-chip>
      </v-col>
      <v-col cols="6" md="2">
        <v-chip
            :color="percentageColor(oce)"
            class="ma-2"
            medium
        >
          {{ oce }}%
          <v-icon>mdi-chart-areaspline-variant</v-icon>
        </v-chip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
            :items="models"
            :value="model"
            dense
            item-text="name"
            item-value="id"
            label="Modelo"
            outlined
            @input="updateModel"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
            :items="businessUnits"
            :value="businessUnit"
            dense
            item-text="name"
            item-value="id"
            label="Unidad de negocio"
            outlined
            @input="updateBusinessUnit"
        ></v-select>
      </v-col>
    </v-row>

    <v-row class="mb-0">
      <v-col>
        <v-simple-table v-if="configuration" dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Tiempo disponible
              </th>
              <th class="text-left">
                Capacidad de la Linea
              </th>
              <th class="text-left">
                Tiempo de ciclo ajustado
              </th>
              <th class="text-left">
                Pz/Conectores x Hr.
              </th>
              <th class="text-left">
                Personal
              </th>
              <th class="text-left">
                PPH
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{ configuration.available_minutes }} Min.</td>
              <td>{{ laneCapacity }}</td>
              <td>{{ adjustedCycle }} Seg/Pz.</td>
              <td>{{ configuration.pieces_by_hour }}</td>
              <td>{{ configuration.personnel }}</td>
              <td>{{ pph }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-4">
      <v-col col="12" md="4">
        <v-btn color="secondary" @click="onEditTodayConfig">
          <v-icon class="mr-1">mdi-clock-edit-outline</v-icon>
          Configuración del Dia
        </v-btn>
      </v-col>

      <v-col col="12" md="4">
        <v-btn color="red accent-2" style="color: white;" @click="$refs.deleteConfirmation.openDialog()">
          <v-icon class="mr-1">mdi-minus-circle</v-icon>
          Eliminar el último registro de piezas
        </v-btn>
      </v-col>

      <v-col class="d-flex justify-end" col="12" md="4">
        <v-btn color="primary" @click="onShowComparison">Comparar Turnos</v-btn>
      </v-col>
    </v-row>

    <v-row class="pt-5">
      <template v-if="showComparison">
        <v-col v-for="shift in shifts" :key="shift.id">
          <v-subheader>
            {{ shift.name }}
          </v-subheader>
          <hour-table v-if="loadedComparison" :key="shift.data.length" :headers="comparisonHeaders"
                      :items="comparisonTableData(shift.data)">
          </hour-table>
        </v-col>
      </template>
      <v-col v-else>
        <hour-table :expanded-item-action="expandedItemAction" :expanded-item-problem="expandedItemProblem"
                    :expanded-item-time="expandedItemTime" :headers="headers" :items="tableData"
                    :current-schedule="Number(currentSchedule)" :problems="problems" :wasted-time="wastedTime"
                    expandable
                    @input="postHourIssue" @itemExpanded="loadIssueData">
        </hour-table>
      </v-col>
    </v-row>

    <!-- Configuration modal -->
    <dialog-form ref="configModal" isForm need-close
                 :title="configModalTitle" @accept="postTodaysConfiguration"
                 @close="redirectToMetrics">
      <template v-slot:form>
        <v-container class="m-4">
          <v-text-field
              v-model="newConfigurationObject.personnel"
              label="Personal"
              outlined
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="newConfigurationObject.pieces_by_hour"
              label="Piezas por hora"
              outlined
              type="number"
              @keyup.enter="$refs.configModal.acceptDialog()"
          ></v-text-field>
        </v-container>
      </template>
    </dialog-form>

    <dialog-form ref="editModal" isForm need-close
                 :title="editModalTitle" @accept="putTodaysConfiguration"
    >
      <template v-slot:form>
        <v-container class="m-4">
          <v-text-field
              v-model="newConfigurationObject.personnel"
              label="Personal"
              outlined
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="newConfigurationObject.pieces_by_hour"
              label="Piezas por hora"
              outlined
              type="number"
              @keyup.enter="$refs.editModal.acceptDialog()"
          ></v-text-field>
        </v-container>
      </template>
    </dialog-form>

    <dialog-form ref="pieceModal" isForm need-close
                 :close-on-accept="false" title="Registrar Pieza" @accept="postPieceRegister">
      <template v-slot:form>
        <v-container class="m-4">
          <v-checkbox
              v-model="saveConnectorQuantity"
              label="Dejar guardada la cantidad de piezas por componente"
          ></v-checkbox>
          <v-text-field
              v-model="pieceRegister.connectors"
              :autofocus="!(pieceRegister && saveConnectorQuantity)"
              label="Conectores x pieza"
              outlined
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="pieceRegister.serial_number"
              :autofocus="pieceRegister && saveConnectorQuantity"
              label="No. de serie"
              ref="serialInput"
              outlined
              @keyup.enter="$refs.pieceModal.acceptDialog();"
          ></v-text-field>
        </v-container>
      </template>
    </dialog-form>

    <dialog-form ref="deleteConfirmation" :close-on-accept="true" isForm
                 need-close title="¿Estás seguro?" @accept="deletePieceRegister">
      <template v-slot:form>
        <v-container class="m-4">
          <p>
            Estás por eliminar el último registro de piezas. ¿Estás seguro?
          </p>
        </v-container>
      </template>
    </dialog-form>

    <!-- Floating button -->
    <v-fab-transition>
      <v-btn
          bottom
          color="primary"
          dark
          fab
          fixed
          right
          @click="checkIfCanRegister"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import HourTable from "@/components/HourTable";
import axios from "axios";
import DialogForm from "@/components/DialogForm";
import logo from "../assets/logo.jpg"
import MetricsEnum from "../enums/modules/MetricsEnum";
import ConfigurationTitle from "@/enums/modules/ConfigurationTitleHH"

export default {
  name: "HourByHour",
  components: {
    HourTable,
    DialogForm
  },
  inject: ["showSnackbar"],
  data() {
    return {
      logo: logo,
      items: [],
      configuration: null,
      configModalTitle: ConfigurationTitle.NEW,
      editModalTitle: ConfigurationTitle.EDIT,
      problems: [],
      wastedTime: [],
      headers: [
        {text: 'Hora Inicio', value: 'start_hour', sortable: false},
        {text: 'Hora Fin', value: 'end_hour', sortable: false},
        {text: 'Minutos Disponibles', value: 'available_minutes', sortable: false},
        {text: 'Meta', value: 'goal', sortable: false},
        {text: 'Real', value: 'real', sortable: false},
        {text: 'Meta Acumulado', value: 'goalAccumulated', sortable: false},
        {text: 'Real Acumulado', value: 'realAccumulated', sortable: false},
        {text: 'Acumulado +/-', value: 'accumulated', sortable: false},
      ],
      comparisonHeaders: [
        {text: 'Hora', value: 'start_hour', sortable: false},
        {text: 'Meta', value: 'goal', sortable: false},
        {text: 'Real', value: 'real', sortable: false},
        {text: 'Acumulado +/-', value: 'accumulated', sortable: false},
      ],
      realAccumulated: 0,
      goalAccumulated: 0,
      showComparison: false,
      newConfigurationObject: {
        pieces_by_hour: null,
        personnel: null,
      },
      saveConnectorQuantity: false,
      pieceRegister: {
        serial_number: null,
        connectors: null
      },
      expandedItemProblem: null,
      expandedItemAction: null,
      expandedItemTime: null,
      quality: null,
      scrap: null,
      deliver: null,
      oce: null,
      models: [],
      businessUnits: [],
      shifts: [],
      loadedComparison: false,
    }
  },
  computed: {
    businessUnit() {
      return this.$store.getters["hour/businessUnit"];
    },
    model() {
      return this.$store.getters["hour/model"];
    },
    currentSchedule() {
      return this.$store.getters["hour/currentSchedule"];
    },
    laneCapacity() {
      if (!this.configuration) return 0;
      return (this.configuration.pieces_by_hour * (this.configuration.available_minutes / 60)).toFixed(2);
    },
    adjustedCycle() {
      if (!this.configuration) return 0;
      return ((this.configuration.available_minutes * 60) / this.laneCapacity).toFixed(2);
    },
    pph() {
      if (!this.configuration) return 0;
      return (this.configuration.pieces_by_hour / this.configuration.personnel).toFixed(1);
    },
    goalFactor() {
      if (!this.configuration) return 0;
      return (this.configuration.pieces_by_hour / 60)
    },
    tableData() {
      return this.items.map((value, idx, arr) => {
        let item = {...value}
        if(item.available_minutes === 60){
          item["goal"] = this.configuration.pieces_by_hour;
        }
        else {
          item["goal"] = Math.ceil(this.goalFactor * item.available_minutes);
        }
        item.real = item.real ? Number(item.real) : 0;

        if (idx === 0) {
          this.realAccumulated = item["realAccumulated"] = item.real;
          this.goalAccumulated = item["goalAccumulated"] = item.goal;
        } else {
          this.realAccumulated = item["realAccumulated"] = (this.realAccumulated + item.real);
          this.goalAccumulated = item["goalAccumulated"] = (this.goalAccumulated + item.goal);
        }

        item["accumulated"] = item.realAccumulated - item.goalAccumulated;

        return item;
      })
    },
    comparisonTableData() {
      return (data) => {
        let realAccumulated = 0;
        let goalAccumulated = 0;
        const tableData = data.map((value, idx, arr) => {
          let item = {...value}
          if(item.available_minutes === 60){
            item["goal"] = this.configuration.pieces_by_hour;
          }
          else {
            item["goal"] = Math.ceil(this.goalFactor * item.available_minutes);
          }
          item.real = item.real ? Number(item.real) : 0;

          if (idx === 0) {
            realAccumulated = item["realAccumulated"] = item.real;
            goalAccumulated = item["goalAccumulated"] = item.goal;
          } else {
            realAccumulated = item["realAccumulated"] = (realAccumulated + item.real);
            goalAccumulated = item["goalAccumulated"] = (goalAccumulated + item.goal);
          }

          item["accumulated"] = item.realAccumulated - item.goalAccumulated;

          return item;
        })

        return tableData;
      }
    },
    percentageColor() {
      return (item) => {
        if (item >= 85) {
          return "#4CAF50";
        } else if (item >= 75 && item < 85) {
          return "#FF9800";
        } else {
          return "#F44336";
        }
      }
    },
    scrapPercentageColor() {
      return (item) => {
        if (item >= 85) {
          return "#F44336";
        } else if (item >= 50 && item < 85) {
          return "#FF9800";
        } else {
          return "#4CAF50";
        }
      }
    },
    lane() {
      return this.$store.getters["auth/lane"]
    },
    shift() {
      return this.$store.getters["auth/shift"]
    },
  },
  methods: {
    getTodaysConfiguration() {
      axios.get(process.env.VUE_APP_APIURL + `today/${this.lane}/${this.shift}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.configuration = response.data;
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.$refs.configModal.openDialog();
            } else {
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado");
            }
          })
    },
    getHourByHourData() {
      this.items = [];
      axios.get(process.env.VUE_APP_APIURL + `hxh/${this.shift}/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.items = response.data;
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado");
          })
    },
    getProblems() {
      axios.get(process.env.VUE_APP_APIURL + "issue/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.problems = response.data;
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado");
          })
    },
    getWastedTime() {
      let time = []
      for (let i = 0; i <= 60; i += 5) {
        time.push(i);
      }
      this.wastedTime = [...time];
    },
    onAddPieceRegister() {
      if (this.currentSchedule === null) {
        this.showSnackbar("error", "No hay horario activo.");
        return;
      }
      if (this.$store.getters["hour/blockAddButton"]) {
        this.showSnackbar("error", "Necesitas registrar el problema en el horario anterior.");
        return;
      }
      this.$refs.pieceModal.openDialog();
    },
    postPieceRegister() {
      if (this.pieceRegister.serial_number.length !== 4 &&
          this.pieceRegister.serial_number.length !== 11 &&
          this.pieceRegister.serial_number.length !== 12) {
        this.showSnackbar("error", "El serial no tiene la longitud necesaria de 4, 11 o 12 caracteres.");
        return;
      }

      this.pieceRegister["lane_id"] = this.lane;
      this.pieceRegister["schedule_id"] = this.currentSchedule;
      this.pieceRegister["model_id"] = this.model;
      this.pieceRegister["business_id"] = this.businessUnit;

      if (this.model === null) {
        this.showSnackbar("error", "Debes seleccionar modelo.");
        return;
      }

      if (this.businessUnit === null) {
        this.showSnackbar("error", "Debes seleccionar unidad de negocio.");
        return;
      }

      axios.post(process.env.VUE_APP_APIURL + "pieceRegister/", this.pieceRegister, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getHourByHourData();
            this.showSnackbar("success", "Pieza registrada correctamente");

            this.pieceRegister.serial_number = null;
            if (!this.saveConnectorQuantity) {
              this.pieceRegister.connectors = null;
            }

            this.$refs.serialInput.focus();
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          })
    },
    deletePieceRegister() {
      axios.delete(process.env.VUE_APP_APIURL + `pieceRegister/${this.lane}/${this.shift}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getHourByHourData();
            this.showSnackbar("success", "Último registrado de piezas eliminado correctamente");
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          })
    },
    postHourIssue(item) {
      item["lane_id"] = this.lane;
      axios.post(process.env.VUE_APP_APIURL + "issueRegister/", item, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getHourByHourData();
            this.$store.dispatch("hour/unblockAddButton");
            this.showSnackbar("success", "Problema registrado correctamente");
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
          })
    },
    postTodaysConfiguration() {
      this.newConfigurationObject["lane_id"] = this.lane;
      this.newConfigurationObject["shift_id"] = this.shift;

      axios.post(process.env.VUE_APP_APIURL + "today/", this.newConfigurationObject, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getTodaysConfiguration();
            this.showSnackbar("success", "Configuración de hoy creada correctamente");
            this.newConfigurationObject = {
              ...{
                pieces_by_hour: null,
                personnel: null,
              }
            }
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
            this.$refs.configModal.openDialog();
          })
    },
    putTodaysConfiguration() {
      this.newConfigurationObject["lane_id"] = this.lane;

      axios.put(process.env.VUE_APP_APIURL + `today/${this.lane}/${this.shift}`, this.newConfigurationObject, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.getTodaysConfiguration();
            this.showSnackbar("success", "Configuración del turno actualizada correctamente");
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado, intenta de nuevo");
            this.$refs.editModal.openDialog();
          })
    },
    loadIssueData(item) {
      axios.get(process.env.VUE_APP_APIURL + `issueRegister/${item.id}/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.expandedItemProblem = response.data.issue;
            this.expandedItemTime = response.data.time_lost;
            this.expandedItemAction = response.data.action;
          })
          .catch(error => {
            if (error.response.status !== 404) {
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado");
            } else {
              this.expandedItemProblem = null;
              this.expandedItemTime = null;
              this.expandedItemAction = null;
            }
          })
    },
    getQuality() {
      axios.get(process.env.VUE_APP_APIURL + `metricPercentage/${MetricsEnum.QUALITY}/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.quality = response.data.percentage;
          })
    },
    getDeliver() {
      axios.get(process.env.VUE_APP_APIURL + `metricPercentage/${MetricsEnum.DELIVER}/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.deliver = response.data.percentage;
          })
    },
    getScrap() {
      axios.get(process.env.VUE_APP_APIURL + `metricPercentage/${MetricsEnum.SCRAP}/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.scrap = response.data.percentage;
          })
    },
    getOce() {
      axios.get(process.env.VUE_APP_APIURL + `metricPercentage/${MetricsEnum.OCE}/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.oce = response.data.percentage;
          })
    },
    onShowComparison() {
      this.showComparison = !this.showComparison;
      if (this.showComparison) {
        this.getShifts();
      } else {
        this.loadedComparison = false;
      }
    },
    getShifts() {
      axios.get(process.env.VUE_APP_APIURL + "shifts/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            response.data.forEach(value => value["data"] = []);
            this.getDataForShifts(response.data);
          })
    },
    onEditTodayConfig() {
      this.$refs.editModal.openDialog();
    },
    getDataForShifts(data) {
      const shifts = [...data]
      shifts.forEach(value => {
        axios.get(process.env.VUE_APP_APIURL + `hxh/${value.id}/${this.lane}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
            .then(response => {
              value["data"] = [...response.data];
            })
            .catch(error => {
              this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado");
            });
      })

      this.shifts = [...shifts];
      this.loadedComparison = true;
    },
    redirectToMetrics() {
      this.$router.push({name: "Charts"})
    },
    getModels() {
      axios.get(process.env.VUE_APP_APIURL + "model/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.models = response.data;
          })
    },
    getBusinessUnits() {
      axios.get(process.env.VUE_APP_APIURL + "businessUnit/", {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            this.businessUnits = response.data;
          })
    },
    getCurrentSchedule() {
      axios.get(process.env.VUE_APP_APIURL + "currentSchedule/" + this.lane, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            if (this.$store.getters["hour/currentSchedule"] === response.data.id) {

            } else {
              this.$store.dispatch("hour/updateCurrentSchedule", response.data.id);
              this.getHourByHourData();
            }
          }).catch(error => {
        this.$store.dispatch("hour/updateCurrentSchedule", null)
        this.showSnackbar("error", error.response.data.message ? error.response.data.message : "No se pudo encontrar horario activo.");
      })
    },
    onLoadConfig() {
      this.getTodaysConfiguration();
      this.getHourByHourData();
      this.getProblems();
      this.getWastedTime();
      this.getOce();
      this.getDeliver();
      this.getQuality();
      this.getScrap();
      this.getBusinessUnits();
      this.getModels();
      this.getCurrentSchedule();
    },
    updateModel(modelId) {
      this.$store.dispatch("hour/updateModel", modelId);
    },
    updateBusinessUnit(businessUnitId) {
      this.$store.dispatch("hour/updateBusinessUnit", businessUnitId);
    },
    checkIfCanRegister() {
      axios.get(process.env.VUE_APP_APIURL + `checkIfCanMakeRegister/${this.lane}/${this.shift}`, {headers: {Authorization: `Bearer ${this.$store.getters["auth/token"]}`}})
          .then(response => {
            if (response.data.canRegister) {
              this.$store.dispatch("hour/unblockAddButton");
              this.onAddPieceRegister();
            } else {
              this.$store.dispatch("hour/blockAddButton");
              this.showSnackbar("error", "Falta de registrar problemas en " + response.data.missing + " horas");
            }
          })
          .catch(error => {
            this.showSnackbar("error", error.response.data.message ? error.response.data.message : "Ocurrió un error inesperado");
          })
    },
  },
  mounted() {
    this.onLoadConfig();
  },
  watch: {
    lane(newValue, oldValue) {
      this.onLoadConfig();
    },
    shift(newValue, oldValue) {
      this.$store.dispatch("hour/unblockAddButton");
      this.$store.dispatch("hour/updateCurrentSchedule", null);
      this.onLoadConfig();
    },
  },
}
</script>

<style scoped>

.big-text {
  font-size: 24px;
}
</style>