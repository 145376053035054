<template>
  <v-container>
    <v-expansion-panels v-model="expandedDay" accordion>
      <v-expansion-panel
          v-for="day in days"
          :key="day.id"
      >
        <v-expansion-panel-header>{{ day.day }} {{ day.date.getDate() }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table :headers="headers" :items="data" class="elevation-1" hide-default-footer
                        item-key="name">
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    month: {
      type: Number,
      default: new Date().getMonth()
    },
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
    week: {
      type: Number,
      required: true
    },
    headers: {
      type: Array,
      default: [
        {
          text: "Problema",
          align: "start",
          sortable: false,
          value: "issue__name",
        },
        {
          text: "Tiempo Perdido",
          value: "time",
        },
        {
          text: "Numero de Incidencias",
          value: "incidents",
        },
      ]
    }
  },
  data() {
    return {
      expanded: [],
      expandedDay: null,
      days: []
    };
  },
  watch: {
    expandedDay(val) {
      this.$emit("expandedDay", this.days[val]);
    }
  },
  methods: {
    daysInWeek() {
      const currentDate = new Date();
      let weekdays = [];
      let firstDayOfWeek = (this.week * 7) - 6;
      for (let i = firstDayOfWeek; i < firstDayOfWeek + 7; i++) {
        let item = {
          id: i,
          date: new Date(this.year, this.month, i),
        };

        if (item.date > currentDate) break;

        item["day"] = this.numberToDate(item.date.getDay());
        weekdays.push(item);
      }
      this.days = weekdays;
    },
    numberToDate(day) {
      switch (day) {
        case 0:
          return "Domingo";
        case 1:
          return "Lunes";
        case 2:
          return "Martes";
        case 3:
          return "Miercoles";
        case 4:
          return "Jueves";
        case 5:
          return "Viernes";
        case 6:
          return "Sábado";
      }
    }
  },
  mounted() {
    this.daysInWeek();
  }
};
</script>

<style>
</style>
