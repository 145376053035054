const state = () => ({
    lanes: [{name: "Linea 12", id: 12}, {name: "Linea 13", id: 13}, {name: "Linea 14", id: 14}],
})

// getters
const getters = {
    lanes(state) {
        return state.lanes;
    }
}

// actions
const actions = {
    loadLanes({commit}, lanes) {
        commit("loadLanes", lanes)
    }
}

// mutations
const mutations = {
    loadLanes(state, lanes) {
        state.lanes = [...lanes];
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}